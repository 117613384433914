import type { Theme } from '@ori-ui/mui';

import { WHITE_COLORS } from '../constants';

interface GetColorBoxShadow {
  background: string;
  theme: Theme;
}
export const getColorBoxShadow = ({ background, theme }: GetColorBoxShadow) =>
  WHITE_COLORS.includes(background) ? theme.palette.blueGrey[100] : theme.palette.common.white;
