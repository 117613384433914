/* istanbul ignore file -- query */
import { gql } from '@ori/fetching';

import { productListingFacetsRows } from './fragments';

export const productListingFacetsQuery = gql`
  ${productListingFacetsRows}
  query ProductListingFacets($pageId: ID!, $facetFiltering: [EditorialPlpRowsFacetFilter] = []) {
    application {
      editorialPage(pageId: $pageId, channelType: Web) {
        contentItems {
          __typename
          ...ProductListingFacetsRows
        }
      }
    }
  }
`;
