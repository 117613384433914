import { styled } from '@ori-ui/mui';

export const FilterButton = styled('div')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  userSelect: 'none',
  width: 'fit-content',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
