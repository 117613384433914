import type { ProductListingRowsFragment } from '@ori/editorial-fetching';
import { EditorialPlpPromoBannerSize, PlpOrderBy, ProductLabelDomainType } from '@ori/editorial-fetching';

export const BannerProductListingOnlyBannersMock: ProductListingRowsFragment = {
  __typename: 'EditorialPlpRows',
  totalBannersCount: 3,
  totalProductsCount: 0,
  rows: [
    {
      __typename: 'EditorialPlpRow',
      content: [{ __typename: 'EditorialPlpPromoBanner', banner: {}, size: EditorialPlpPromoBannerSize.Size2 }],
    },
  ],
};

export const BannerProductListingMock: ProductListingRowsFragment = {
  __typename: 'EditorialPlpRows',
  totalProductsCount: 12,
  rows: [
    {
      __typename: 'EditorialPlpRow',
      content: [
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Optimals',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '42553',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Noční krém Optimals Even Out',
            ratingAverage: 4.6512,
            ratingCount: 410,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '42553',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '460,00 Kč',
              currentPrice: '199,90 Kč',
              previousMinOfferPrice: '229,90 Kč',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 199.9,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f42553%2f42553_1.png&id=14861236&version=2',
          productCode: '42553',
          replacedByProduct: null,
          replacementForProduct: null,
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Diamond Cellular',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '18437',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Noční péče pro omlazení pleti Diamond Cellular',
            ratingAverage: 4.7488,
            ratingCount: 2803,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '18437',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '900,00 Kč',
              currentPrice: '439,00 Kč',
              previousMinOfferPrice: '439,00 Kč',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 439,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f18437%2fCZ%2f18437_1.png&id=14989293&version=6',
          productCode: '18437',
          replacedByProduct: {
            productCode: '32544',
          },
          replacementForProduct: null,
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Optimals',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '42549',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Omlazující noční krém Optimals Age Revive',
            ratingAverage: 4.6418,
            ratingCount: 469,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '42549',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '460,00 Kč',
              currentPrice: '229,90 Kč',
              previousMinOfferPrice: '219,90 Kč',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 229.9,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f42549%2f42549_1.png&id=2024-03-06T09-54-07-648Z_MediaMigration&version=1674547201',
          productCode: '42549',
          replacedByProduct: null,
          replacementForProduct: null,
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Novage+',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '38385',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Noční krém pro citlivou pleť NovAge Skinrelief',
            ratingAverage: 4.6552,
            ratingCount: 116,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '38385',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '730,00 Kč',
              currentPrice: '730,00 Kč',
              previousMinOfferPrice: '730,00 Kč',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 730,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f38385%2f38385_1.png&id=15203538&version=3',
          productCode: '38385',
          replacedByProduct: null,
          replacementForProduct: null,
        },
      ],
    },
    {
      __typename: 'EditorialPlpRow',
      content: [
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Optimals',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '42589',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Intenzivně vyživující noční krém Optimals Hydra Radiance',
            ratingAverage: 4.5028,
            ratingCount: 358,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '42589',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '400,00 Kč',
              currentPrice: '189,90 Kč',
              previousMinOfferPrice: '199,90 Kč',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 189.9,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f42589%2f42589_1.png&id=14944540&version=2',
          productCode: '42589',
          replacedByProduct: null,
          replacementForProduct: null,
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Novage+',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '41057',
            labels: [
              {
                labelKey: 'Offer',
                productLabelDomain: ProductLabelDomainType.Deal,
                title: 'Nabídka',
              },
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Lehký omlazující noční krém Multi-Active Novage+',
            ratingAverage: 4.6577,
            ratingCount: 111,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '41057',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '900,00 Kč',
              currentPrice: '900,00 Kč',
              previousMinOfferPrice: '900,00 Kč',
            },
          },
          labels: [
            {
              labelKey: 'Offer',
              productLabelDomain: ProductLabelDomainType.Deal,
              title: 'Nabídka',
            },
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 900,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2f41057%2f41057.png%3fversion%3d1675683039',
          productCode: '41057',
          replacedByProduct: null,
          replacementForProduct: {
            productCode: '33982',
          },
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Novage+',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '41058',
            labels: [
              {
                labelKey: 'Offer',
                productLabelDomain: ProductLabelDomainType.Deal,
                title: 'Nabídka',
              },
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Hutný omlazující noční krém Multi-Active Novage+',
            ratingAverage: 4.7879,
            ratingCount: 198,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '41058',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '900,00 Kč',
              currentPrice: '900,00 Kč',
              previousMinOfferPrice: '900,00 Kč',
            },
          },
          labels: [
            {
              labelKey: 'Offer',
              productLabelDomain: ProductLabelDomainType.Deal,
              title: 'Nabídka',
            },
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 900,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f41058%2f41058_1.png&id=17447607&version=1',
          productCode: '41058',
          replacedByProduct: null,
          replacementForProduct: {
            productCode: '34549',
          },
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Love Nature',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '34845',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Zmatňující pleťové mléko s bio čajovníkem a limetkou Love Nature',
            ratingAverage: 4.6362,
            ratingCount: 470,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '34845',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '200,00 Kč',
              currentPrice: '119,90 Kč',
              previousMinOfferPrice: '99,90 Kč',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 119.9,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f34845%2fCZ%2f34845_1.png&id=2024-03-06T09-17-33-885Z_MediaMigration&version=1643798703',
          productCode: '34845',
          replacedByProduct: null,
          replacementForProduct: null,
        },
      ],
    },
    {
      __typename: 'EditorialPlpRow',
      content: [
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Optimals',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '44260',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Noční krém Optimals Urban Guard 3D',
            ratingAverage: 4.6013,
            ratingCount: 153,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '44260',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '490,00 Kč',
              currentPrice: '490,00 Kč',
              previousMinOfferPrice: '',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 490,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f44260%2f44260_1.png&id=14819095&version=2',
          productCode: '44260',
          replacedByProduct: null,
          replacementForProduct: null,
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Novage+',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '32628',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Noční regenerační krém NovAge Time Restore',
            ratingAverage: 4.7185,
            ratingCount: 920,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '32628',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '880,00 Kč',
              currentPrice: '880,00 Kč',
              previousMinOfferPrice: '880,00 Kč',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 880,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f32628%2f32628_1.png&id=10316285&version=6',
          productCode: '32628',
          replacedByProduct: null,
          replacementForProduct: null,
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Royal Velvet',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '22814',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Obnovující noční krém Royal Velvet',
            ratingAverage: 4.7457,
            ratingCount: 2709,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '22814',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '580,00 Kč',
              currentPrice: '580,00 Kč',
              previousMinOfferPrice: '',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 580,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f22814%2fCZ%2f22814_1.png&id=14989294&version=4',
          productCode: '22814',
          replacedByProduct: null,
          replacementForProduct: {
            productCode: '4643',
          },
        },
        {
          __typename: 'Product',
          backInStockAvailability: {
            showBackInStockNotification: false,
          },
          concept: {
            brand: {
              name: 'Optimals',
            },
            categories: [
              {
                name: 'Péče o pleť',
              },
            ],
            conceptCode: '42587',
            labels: [
              {
                labelKey: 'AddToBasketVisible',
                productLabelDomain: ProductLabelDomainType.Shopping,
                title: null,
              },
            ],
            name: 'Noční krém Optimals Hydra Radiance',
            ratingAverage: 4.5528,
            ratingCount: 322,
            products: [
              {
                hexColors: [],
                colorImageUrl: '',
                productCode: '42587',
              },
            ],
          },
          formattedPrice: {
            price: {
              basicCataloguePrice: '400,00 Kč',
              currentPrice: '400,00 Kč',
              previousMinOfferPrice: '',
            },
          },
          labels: [
            {
              labelKey: 'AddToBasketVisible',
              productLabelDomain: ProductLabelDomainType.Shopping,
              title: null,
            },
          ],
          price: {
            price: {
              currentPrice: 400,
            },
          },
          imageUrl:
            'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f42587%2f42587_1.png&id=2024-03-06T09-54-19-329Z_MediaMigration&version=1674547220',
          productCode: '42587',
          replacedByProduct: null,
          replacementForProduct: null,
        },
      ],
    },
  ],
};

export const BannerProductListingWithFacetsAndOrderByMock: ProductListingRowsFragment = {
  ...BannerProductListingMock,
  orderByOptions: [
    {
      __typename: 'PlpOrderingDefinition',
      displayText: 'Podle doporučení',
      orderBy: PlpOrderBy.Recommended,
    },
    {
      __typename: 'PlpOrderingDefinition',
      displayText: 'Nejnovější',
      orderBy: PlpOrderBy.Latest,
    },
    {
      __typename: 'PlpOrderingDefinition',
      displayText: 'Nejvyšší hodnocení',
      orderBy: PlpOrderBy.HighestRating,
    },
    {
      __typename: 'PlpOrderingDefinition',
      displayText: 'Nejnižší cena',
      orderBy: PlpOrderBy.LowestPrice,
    },
    {
      __typename: 'PlpOrderingDefinition',
      displayText: 'Nejvyšší cena',
      orderBy: PlpOrderBy.HighestPrice,
    },
    {
      __typename: 'PlpOrderingDefinition',
      displayText: 'Největší sleva',
      orderBy: PlpOrderBy.HighestDiscount,
    },
  ],
  facets: [
    {
      name: 'brand',
      active: null,
      displayName: 'brand',
      values: [
        {
          key: 'optimals',
          count: 5,
          active: null,
          displayName: 'optimals',
        },
        {
          key: 'novage',
          count: 2,
          active: null,
          displayName: 'novage',
        },
        {
          key: 'novage-plus',
          count: 2,
          active: null,
          displayName: 'novage-plus',
        },
        {
          key: 'diamond-cellular',
          count: 1,
          active: null,
          displayName: 'diamond-cellular',
        },
        {
          key: 'love-nature',
          count: 1,
          active: null,
          displayName: 'love-nature',
        },
        {
          key: 'pure-skin',
          count: 1,
          active: null,
          displayName: 'pure-skin',
        },
        {
          key: 'royal-velvet',
          count: 1,
          active: null,
          displayName: 'royal-velvet',
        },
        {
          key: 'waunt',
          count: 1,
          active: null,
          displayName: 'waunt',
        },
      ],
    },
  ],
};
