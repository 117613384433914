import { styled, Typography, type TypographyProps } from '@ori-ui/mui';
import { EditorialPlpPromoBannerSize, VerticalContentAlignment } from '@ori/graphql-schema';
import { shouldForwardProp } from '@ori/presentation-utils';

import { BANNER_SIZE_IN_PX } from '../BannerProductListing/constants';
import type { PromoBannerProps } from './types';

export const Banner = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<{
  hasLink: boolean;
  size: EditorialPlpPromoBannerSize;
}>(({ theme, hasLink, size }) => ({
  maxHeight: 385,
  position: 'relative',
  borderRadius: theme.spacing(1),
  overflow: 'hidden',

  '&:hover': {
    '& img': {
      filter: hasLink ? 'brightness(0.8)' : 'none',
      cursor: hasLink ? 'pointer' : 'default',
    },
    '& div': {
      color: hasLink ? theme.palette.common.white : 'auto',
      textDecorationColor: hasLink ? theme.palette.common.white : 'auto',
    },
  },
  order: size === EditorialPlpPromoBannerSize.Size2 ? 5 : 'inherit',
  gridColumn: size === EditorialPlpPromoBannerSize.Size2 ? '1 / 3' : 'inherit',
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    order: 'inherit',
    gridColumn: 'inherit',
    maxWidth: BANNER_SIZE_IN_PX[size],
  },
}));

export const BannerImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const BannerVideo = styled('video')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export const BannerTitleLinkImitation = styled(Typography, {
  shouldForwardProp: shouldForwardProp(),
})<Pick<PromoBannerProps, 'fontColor'>>(({ theme, fontColor }) => ({
  textDecoration: 'underline',
  textTransform: 'uppercase',
  marginTop: theme.spacing(1),
  textUnderlineOffset: theme.spacing(1),
  textDecorationThickness: 2,
}));

export const BannerTextContainer = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<Pick<PromoBannerProps, 'fontColor' | 'verticalAlignment'>>(({ theme, verticalAlignment, fontColor }) => ({
  position: 'absolute',
  top:
    verticalAlignment === VerticalContentAlignment.Middle
      ? '50%'
      : verticalAlignment === VerticalContentAlignment.Top
        ? '20%'
        : '80%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: fontColor,
  textAlign: 'center',
  width: '100%',
  margin: theme.spacing(0, 0, 2),
  padding: theme.spacing(0, 3),
}));
export const BannerTitle = styled(Typography)<Pick<TypographyProps, 'component'>>(({ theme }) => ({
  fontSize: '2rem',
}));
