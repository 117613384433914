import type { TypographyProps } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { COLOR_OPTION_ICON } from '../constants';
import { Color } from '../styles';
import type { FilterItemColor } from '../types';
import { BaseOption } from './BaseOption';

interface ColorOptionProps extends Pick<FilterItemColor, 'hexColors' | 'label' | 'selected'> {
  onClick?: () => void;
  onDelete?: () => void;
  LabelProps?: TypographyProps;
  OptionProps?: {
    height?: number;
    iconSize?: number;
    selectedIconSize?: number;
  };
}

export const ColorOption: FC<ColorOptionProps> = ({
  selected,
  hexColors,
  label,
  onClick,
  onDelete,
  LabelProps,
  OptionProps,
}) => {
  const labelProps: TypographyProps = { fontWeight: selected ? 'bold' : 'regular', ...LabelProps };
  const { getTestId } = useTestId();

  const icon = (
    <Color
      data-testid={getTestId(COLOR_OPTION_ICON)}
      selected={selected}
      hexColors={hexColors}
      selectedIconSize={OptionProps?.selectedIconSize}
      iconSize={OptionProps?.iconSize}
    />
  );

  return (
    <BaseOption
      variant="rounded"
      label={label}
      LabelProps={labelProps}
      height={OptionProps?.height}
      icon={icon}
      onClick={onClick}
      onDelete={onDelete}
    />
  );
};
