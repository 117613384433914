import { Box, useTheme } from '@ori-ui/mui';
import { VerticalContentAlignment } from '@ori/editorial-fetching';
import { EditorialPlpPromoBannerSize } from '@ori/graphql-schema';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import { mapLabels, mapProductBoxPropsFromGql, ProductBox } from '@ori/product-box';
import useTestId from '@ori/testid-generator';
import { type FC, useMemo } from 'react';

import { useBannerProductListingContext, useProductListingContext } from '../../../../contexts';
import { useTranslations } from '../../../../hooks/useTranslations';
import { addPlacementToUrl } from '../../../../utils';
import { PromoBanner } from '../../../PromoBanner';
import { BANNER_SIZE_IN_PX, EMPTY_BOX } from '../../constants';
import { getQuickBuyButtonOnClickHandlers } from '../../utils';
import type { ContentRowProps } from './types';

export const ContentRow: FC<ContentRowProps> = ({ data }) => {
  const theme = useTheme();
  const { getTestId } = useTestId();
  const { favoritesEnabled, hideRating, placement } = useProductListingContext();
  const { translations } = useTranslations();
  const { isPLP } = useBannerProductListingContext();

  const productBoxTranslations = useMemo(
    () => ({
      favorites: translations.favorites,
      quickBuy: translations.quickBuy,
    }),
    [translations.favorites, translations.quickBuy],
  );

  return data?.content?.map((content, index) => {
    if (content?.__typename === 'Product') {
      const productBoxData = mapProductBoxPropsFromGql(content);
      if (productBoxData?.code && productBoxData.name && productBoxData.price?.currentFormatted) {
        const { onNotifyMeButtonClick, onFavoritesButtonClick } = getQuickBuyButtonOnClickHandlers(productBoxData.code);

        return (
          <ProductBox
            key={productBoxData.code}
            {...productBoxData}
            url={addPlacementToUrl({
              placement,
              url: productBoxData.url,
            })}
            labels={mapLabels(content.concept?.labels, isPLP ? undefined : { deal: [] })}
            analyticsData={{
              placement,
              position: index,
            }}
            hideRating={hideRating}
            buttons={{
              ...productBoxData.buttons,
              favorites: {
                ...productBoxData.buttons?.favorites,
                visible: favoritesEnabled,
              },
            }}
            translations={productBoxTranslations}
            onFavoritesButtonClick={onFavoritesButtonClick}
            onNotifyMeButtonClick={onNotifyMeButtonClick}
          />
        );
      }

      return (
        <Box
          // eslint-disable-next-line react/no-array-index-key -- No suitable key */
          key={index}
          data-testid={getTestId(EMPTY_BOX)}
        />
      );
    }

    if (content?.__typename === 'EditorialPlpPromoBanner' && content.banner?.modules?.[0]) {
      if (
        !content.banner.modules[0].backgroundImage?.url &&
        !content.banner.modules[0].content?.title &&
        !content.banner.modules[0].link?.relativeUrl &&
        !content.banner.modules[0].backgroundVideo?.id
      ) {
        return (
          <Box
            // eslint-disable-next-line react/no-array-index-key -- No suitable key */
            key={index}
            data-testid={getTestId(EMPTY_BOX)}
            width={
              content.size == null
                ? BANNER_SIZE_IN_PX[EditorialPlpPromoBannerSize.Size1]
                : BANNER_SIZE_IN_PX[content.size]
            }
          />
        );
      }

      const { titleColor } = content.banner.modules[0].content ?? {};
      const mappedTitleColor = mapBackendColorToTheme({
        backendColor: titleColor,
        fallBackColor: theme.palette.common.white,
        theme,
      });

      return (
        <PromoBanner
          key={content.banner.modules[0].backgroundImage?.url}
          link={content.banner.modules[0].link ?? undefined}
          title={content.banner.modules[0]?.content?.title ?? ''}
          backgroundVideo={content.banner.modules[0].backgroundVideo ?? undefined}
          fontColor={mappedTitleColor}
          verticalAlignment={content.banner.modules[0]?.content?.verticalAlignment ?? VerticalContentAlignment.Middle}
          backgroundImage={content.banner.modules[0].backgroundImage?.url ?? ''}
          size={content.size ?? EditorialPlpPromoBannerSize.Size1}
        />
      );
    }

    return null;
  });
};
