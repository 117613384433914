import { MenuItem, Popper, styled } from '@ori-ui/mui';

export const SortMenu = styled(Popper)(({ theme }) => ({
  width: 330,
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(0),
  zIndex: theme.zIndex.fab,
}));

export const SortItem = styled(MenuItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  padding: theme.spacing(2, 3),
}));

export const SortButton = styled('div')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  userSelect: 'none',
  width: 'fit-content',
  gap: theme.spacing(1),
  alignItems: 'center',
}));
