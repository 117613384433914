import { NoSsr } from '@ori-ui/mui';
import { useToken } from '@ori/auth';
import { EditorialCarouselSection, mapEditorialCarousel } from '@ori/editorial-components';
import { createBannerLayout } from '@ori/editorial-page';
import { useEnvironment } from '@ori/environment';
import { OlapicTypeEnum } from '@ori/olapic-lib';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BannerProductListing, HeaderArea } from '../../components';
import { useGetProductListingData, useGetProductListingFacets } from '../../components/BannerProductListing/utils';
import { BannerProductListingProvider, useProductListingContext } from '../../contexts';
import { useTranslations } from '../../hooks/useTranslations';
import { PRODUCT_LISTING_PAGE } from './constants';
import {
  CarouselInner,
  CarouselRoot,
  EditorialBannersRoot,
  EditorialCarouselSectionRoot,
  EditorialRoot,
  Root,
  StyledOlapicWidget,
  StyledProductCarousel,
  styledProductCarouselRoot,
} from './styles';
import type { ProductListingPageProps } from './types';

/**
 * Used to render product listing page
 *
 * @param breadcrumbs
 * @param data
 * @param olapicConfiguration
 * @param pageId
 * @constructor
 */
export const ProductListingPage: FC<ProductListingPageProps> = ({ data, pageId }) => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();
  const { hideRating } = useProductListingContext();
  const { getFacets } = useGetProductListingFacets({ pageId });
  const { getProductListingData } = useGetProductListingData({ pageId });
  const { customerId } = useToken();
  const { tenant } = useEnvironment();

  if (!data?.application?.editorialPage?.contentItems) {
    return null;
  }

  const { contentItems } = data.application.editorialPage;

  const pageLayout = contentItems.map((item, modulePosition) => {
    switch (item?.__typename) {
      case 'EditorialHeader':
        return (
          <HeaderArea
            breadcrumbs={data.application?.breadcrumbLinks}
            banner={item}
            pageNavigation={data.application?.pageNavigation}
          />
        );
      case 'EditorialPlpRows':
        return (
          <BannerProductListingProvider
            isPLP
            pageId={pageId}
            data={item}
            getFacets={getFacets}
            getData={getProductListingData}
          >
            <BannerProductListing />
          </BannerProductListingProvider>
        );

      case 'EditorialBanner':
        return (
          <EditorialRoot>
            <EditorialBannersRoot>
              {createBannerLayout({ banner: item, debug: false, modulePosition })}
            </EditorialBannersRoot>
          </EditorialRoot>
        );

      case 'EditorialProductBoxCarousel':
        return (
          <CarouselRoot>
            <CarouselInner>
              <StyledProductCarousel
                debug={false}
                modulePosition={modulePosition}
                customerId={customerId}
                hideRating={hideRating}
                headline={item.headline}
                products={item.products}
                tenant={tenant}
                classNames={{
                  carousel: styledProductCarouselRoot,
                }}
                id={`plp-product-carousel-${modulePosition}`}
              />
            </CarouselInner>
          </CarouselRoot>
        );
      case 'EditorialOlapic':
        return (
          <NoSsr>
            <StyledOlapicWidget
              type={item.type ?? OlapicTypeEnum.FrontPage}
              categoryId={item.categoryId ?? undefined}
              productCode={item.product?.productCode}
            />
          </NoSsr>
        );
      case 'EditorialCarousel': {
        return (
          <CarouselRoot>
            <EditorialCarouselSectionRoot>
              <EditorialCarouselSection
                data={mapEditorialCarousel({
                  slides: item.editorials,
                })}
                labelRead={translations.readNow}
                title={translations.inspirationCorner}
                id={`plp-related-articles-carousel-${modulePosition}`}
              />
            </EditorialCarouselSectionRoot>
          </CarouselRoot>
        );
      }
      default:
        return null;
    }
  });

  return <Root data-testid={getTestId(PRODUCT_LISTING_PAGE)}>{pageLayout}</Root>;
};
