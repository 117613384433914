/* istanbul ignore file */
import type { ClientServiceUrls } from '@ori/configuration-manager';
import type {
  EditorialPlpRowsFacetFilter,
  ProductListingPagePaginationQuery,
  ProductListingPagePaginationQueryVariables,
} from '@ori/editorial-fetching';
import { productListingQueryPagination } from '@ori/editorial-fetching';
import type { Headers } from '@ori/fetching';
import { fetchGraphql } from '@ori/fetching';
import type { PlpOrderBy } from '@ori/graphql-schema';
import { EditorialChannelType } from '@ori/graphql-schema';

import { mainLogger } from '../../../logger';

const localLogger = mainLogger.createChild('loadProductListingData');

interface LoadProductListingData extends Required<Pick<ClientServiceUrls, 'graphQlUrl'>> {
  limit: number;
  skip: number;
  pageId: string;
  orderBy: PlpOrderBy;
  facetFiltering?: EditorialPlpRowsFacetFilter[];
  headers: Headers;
}

export const loadProductListingData = async ({
  headers,
  orderBy,
  pageId,
  skip,
  graphQlUrl,
  limit,
  facetFiltering = [],
}: LoadProductListingData) => {
  try {
    return await fetchGraphql<ProductListingPagePaginationQuery, ProductListingPagePaginationQueryVariables>({
      query: productListingQueryPagination,
      variables: {
        channelType: EditorialChannelType.Web,
        limit,
        skip,
        pageId,
        orderBy,
        facetFiltering,
      },
      url: graphQlUrl,
      headers,
    });
  } catch (error) {
    localLogger.error(new Error('fetchGraphql productListingQueryPagination', { cause: error }));
  }

  return undefined;
};
