import { ItemsCounter } from '@ori/presentation-components';
import type { FC } from 'react';

import { useBannerProductListingContext } from '../../../../contexts';
import { useTranslations } from '../../../../hooks/useTranslations';
import { Root } from './styles';

export const ProductsCounter: FC = () => {
  const { content, isPLP } = useBannerProductListingContext();
  const { formatMessage } = useTranslations();
  const totalProductsCount = content?.totalProductsCount ?? 0;

  if (isPLP && totalProductsCount === 0) {
    return null;
  }

  return (
    <Root>
      <ItemsCounter
        text={formatMessage('xProducts', {
          value: totalProductsCount,
        })}
      />
    </Root>
  );
};
