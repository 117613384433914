import { Clear } from '@ori-ui/icons';
import { Button, ClickAwayListener, Typography } from '@ori-ui/mui';
import { useListenToKeyDown } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import type { FC, PropsWithChildren } from 'react';

import {
  FILTER_DROPDOWN,
  FILTER_DROPDOWN_CLEAR_BUTTON,
  FILTER_DROPDOWN_CLOSE_BUTTON,
  FILTER_DROPDOWN_SUBMIT_BUTTON,
  POPPER_MODIFIERS,
} from './constants';
import { ActionContainer, CloseButton, Content, FilterDropdownRoot, Dropdown, TitleContainer } from './styles';
import type { FilterDropdownProps } from './types';

export const FilterDropdown: FC<PropsWithChildren<FilterDropdownProps>> = ({
  isOpen,
  onClose,
  onClear,
  onSubmit,
  title,
  children,
  PaperProps,
  anchorEl,
  placement,
  settings,
  showTitle = true,
}) => {
  const { getTestId } = useTestId();

  useListenToKeyDown(['Escape'], () => {
    onClose();
  });

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Dropdown
        data-testid={getTestId(FILTER_DROPDOWN)}
        open={isOpen}
        anchorEl={anchorEl}
        placement={placement}
        modifiers={POPPER_MODIFIERS}
      >
        <FilterDropdownRoot
          showTitle={showTitle}
          elevation={0}
          {...PaperProps}
        >
          {title && showTitle ? (
            <TitleContainer>
              <Typography
                variant="h6"
                component="h6"
              >
                {title}
              </Typography>
            </TitleContainer>
          ) : null}
          <Content>{children}</Content>
          <ActionContainer>
            <Button
              fullWidth
              color="primary"
              data-testid={getTestId(FILTER_DROPDOWN_SUBMIT_BUTTON)}
              variant="contained"
              onClick={onSubmit}
            >
              {settings.submitButtonTitle}
            </Button>
            <Button
              fullWidth
              data-testid={getTestId(FILTER_DROPDOWN_CLEAR_BUTTON)}
              variant="text"
              onClick={onClear}
            >
              {settings.clearButtonTitle}
            </Button>
          </ActionContainer>
          <CloseButton
            color="default"
            variant="text"
            data-testid={getTestId(FILTER_DROPDOWN_CLOSE_BUTTON)}
            onClick={onClose}
          >
            <Clear
              fontSize="small"
              color="inherit"
            />
          </CloseButton>
        </FilterDropdownRoot>
      </Dropdown>
    </ClickAwayListener>
  );
};
