// Graphql query using rows not items
export const PAGINATION_LIMIT_ROWS = 10;
export const SERVER_PAGINATION_LIMIT_ROWS = 2;
export const CLIENT_PAGINATION_LIMIT_ROWS = PAGINATION_LIMIT_ROWS - SERVER_PAGINATION_LIMIT_ROWS;
// 4 Items per row
export const PAGINATION_LIMIT_ITEMS = SERVER_PAGINATION_LIMIT_ROWS * 4;

export const BANNER_SIZE_IN_PX = {
  Size1: 234,
  Size2: 483,
};

// Test IDs
export const BANNER_PRODUCT_LISTING = 'BannerProductListing';
export const BANNER_PRODUCT_LISTING_DIVIDER = 'banner-product-listing-divider';
export const CONTENT_ROWS = 'ContentRows';
export const EMPTY_BOX = 'EmptyBox';
export const PRODUCT_BOX_SKELETON = 'ProductBoxSkeleton';
export const ORIGIN_QUERY_KEY = 'origin';
