import * as OpenProductInfoDialog from '@ori-events/open-product-info-dialog';
import { DialogMode } from '@ori-events/open-product-info-dialog';
import { Placement } from '@ori/analytics-data';

const createHandler = (mode: DialogMode, productCode: string) => () => {
  window.dispatchEvent(
    new CustomEvent<OpenProductInfoDialog.Data>(OpenProductInfoDialog.Name, {
      detail: {
        mode,
        placement: Placement.ProductListing,
        productCodes: [productCode],
      },
    }),
  );
};
export const getQuickBuyButtonOnClickHandlers = (productCode: string) => {
  const onFavoritesButtonClick = createHandler(DialogMode.Favorites, productCode);
  const onNotifyMeButtonClick = createHandler(DialogMode.StandardBuy, productCode);

  return { onFavoritesButtonClick, onNotifyMeButtonClick };
};
