import { Placement } from '@ori/analytics-data';

import { ORIGIN_QUERY_KEY } from '../components/BannerProductListing/constants';

interface AddPlacementToUrl {
  url: string;
  placement: Placement;
  ignoredPlacements?: Placement[];
}
export const addPlacementToUrl = ({
  url: path,
  placement,
  ignoredPlacements = [Placement.ProductListing],
}: AddPlacementToUrl) => {
  if (ignoredPlacements.includes(placement)) {
    return path;
  }

  const [baseUrl, queryString = ''] = path.split('?');
  const params = new URLSearchParams(queryString);
  params.set(ORIGIN_QUERY_KEY, encodeURIComponent(placement));

  return `${baseUrl}?${params.toString()}`;
};
