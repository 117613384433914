import { ArrowUpThin, ArrowDownThin } from '@ori-ui/icons';
import { Badge, Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import {
  ARROW_UP_ICON,
  ARROW_DOWN_ICON,
  FACET_FILTER_HEADER,
  FACET_FILTER_HEADER_TITLE,
  FACET_FILTER_BADGE,
} from './constants';
import { Root, TitleLayout, Inner, IconContainer } from './styles';
import type { FacetFilterHeaderProps } from './types';

export const FacetFilterHeader: FC<FacetFilterHeaderProps> = ({
  amountSelected,
  amountTotal,
  isOpen,
  title,
  onClick,
  showArrow = true,
  showAmount = true,
  showAmountTotal = true,
  isFirst = false,
}) => {
  const canShowTotalAmount = showAmountTotal && amountTotal !== undefined;
  const { getTestId } = useTestId();
  const titleText = canShowTotalAmount ? `${title} (${amountTotal})` : title;

  return (
    <Root
      data-testid={getTestId(FACET_FILTER_HEADER)}
      isFirst={isFirst}
      onClick={onClick}
    >
      <Inner>
        <TitleLayout>
          <Typography data-testid={getTestId(FACET_FILTER_HEADER_TITLE)}>{titleText}</Typography>
        </TitleLayout>
        {showAmount && Boolean(amountSelected) ? (
          <Badge
            data-testid={getTestId(FACET_FILTER_BADGE)}
            color="primary"
            badgeContent={amountSelected}
            max={99}
          />
        ) : null}
      </Inner>
      {showArrow ? (
        <IconContainer>
          {isOpen ? (
            <ArrowUpThin data-testid={getTestId(ARROW_UP_ICON)} />
          ) : (
            <ArrowDownThin data-testid={getTestId(ARROW_DOWN_ICON)} />
          )}
        </IconContainer>
      ) : null}
    </Root>
  );
};
