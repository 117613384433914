import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BannerProductListing, BannerProductListingSkeleton, SearchResultsHeader } from '../../components';
import { useGetSearchResultsFacets } from '../../components/BannerProductListing/utils';
import { useGetInitialSearchResultsData } from '../../components/BannerProductListing/utils/useGetInitialSearchResultsData';
import { useGetSearchResultsData } from '../../components/BannerProductListing/utils/useGetSearchResultsData';
import { BannerProductListingProvider } from '../../contexts/bannerProductListingContext';
import { SEARCH_RESULTS_PAGE } from './constants';
import { Root } from './styles';
import { getSearchResultsQuery } from './utils';

/**
 * Used to render search results page.
 *
 * @param facets - facets for search page.
 * @param orderByOptions - order by options for search results page.
 *
 * @constructor
 */
export const SearchResultsPage: FC = () => {
  const { getTestId } = useTestId();
  const query = getSearchResultsQuery();
  const { data, loaded } = useGetInitialSearchResultsData({ query });
  const { getSearchResultsData } = useGetSearchResultsData({ query });
  const { getFacets } = useGetSearchResultsFacets({ query });

  return (
    <Root data-testid={getTestId(SEARCH_RESULTS_PAGE)}>
      <SearchResultsHeader
        query={query}
        loaded={loaded}
      />
      {loaded ? (
        <BannerProductListingProvider
          data={data}
          getFacets={getFacets}
          getData={getSearchResultsData}
        >
          <BannerProductListing />
        </BannerProductListingProvider>
      ) : (
        <BannerProductListingSkeleton hasBottomDivider={false} />
      )}
    </Root>
  );
};
