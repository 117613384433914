import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { Actions } from './components/Actions';
import { BannerProductListingPagination as Pagination } from './components/BannerProductListingPagination';
import { Content } from './components/Content';
import { ProductsCounter } from './components/ProductsCounter';
import { BANNER_PRODUCT_LISTING, BANNER_PRODUCT_LISTING_DIVIDER } from './constants';
import { Inner, Root, StyledDivider } from './styles';
import type { BannerProductListingProps } from './types';

export const BannerProductListing: FC<BannerProductListingProps> = ({ className, hasBottomDivider = true }) => {
  const { getTestId } = useTestId();

  return (
    <Root>
      <Inner
        className={className}
        data-testid={getTestId(BANNER_PRODUCT_LISTING)}
      >
        <Actions />
        <ProductsCounter />
        <Content />
        <Pagination />
        {hasBottomDivider ? <StyledDivider data-testid={getTestId(BANNER_PRODUCT_LISTING_DIVIDER)} /> : null}
      </Inner>
    </Root>
  );
};
