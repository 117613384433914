/* istanbul ignore file */

/**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */
import type { TranslationKey } from '../models/TranslationKey';

export type DefaultTranslations = Record<keyof typeof TranslationKey, string>;

export const defaultTranslations: DefaultTranslations = {
  apply: 'Apply',
  clear: 'Clear',
  clearAllFilters: 'Clear all filters',
  favorites: 'Favorites',
  filter: 'Filter',
  inspirationCorner: 'Inspiration corner',
  quickBuy: 'Quick buy',
  readNow: 'Read now',
  showMore: 'Show more',
  showingResultsFor: 'Showing results for',
  showingXOutOfYProducts: 'Showing {value} out of {total} products',
  xProducts: '{value} products',
};

export const translationsNamespace = 'products_product-listing';
