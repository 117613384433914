import type { SelectedFilterItem } from '../components';
import type { FacetFilterValues } from '../components/FacetFilter/types';
import { FilterOptionType } from '../components/FilterOption/types';

export const facets: FacetFilterValues[] = [
  {
    key: 'colors',
    label: 'Colors',
    items: [
      { value: '1', label: 'Red', hexColors: ['#ff0000'], selected: false, type: FilterOptionType.Color },
      { value: '2', label: 'Blue', hexColors: ['#0000ff'], selected: true, type: FilterOptionType.Color },
      { value: '3', label: 'Green', hexColors: ['#00ff00'], selected: false, type: FilterOptionType.Color },
      { value: '4', label: 'Yellow', hexColors: ['#ffff00'], selected: true, type: FilterOptionType.Color },
      { value: '5', label: 'Black', hexColors: ['#000000'], selected: true, type: FilterOptionType.Color },
    ],
  },
  {
    key: 'category',
    label: 'Category',
    items: [
      { value: '1', label: 'Electronics', selected: false },
      { value: '2', label: 'Books', selected: false },
      { value: '3', label: 'Clothing', selected: false },
      { value: '4', label: 'Home & Kitchen', selected: false },
      { value: '5', label: 'Toys & Games', selected: false },
      { value: '6', label: 'Beauty & Personal Care', selected: false },
      { value: '7', label: 'Sports & Outdoors', selected: false },
      { value: '8', label: 'Health & Household', selected: false },
      { value: '9', label: 'Baby', selected: false },
      { value: '10', label: 'Tools & Home Improvement', selected: false },
      { value: '11', label: 'Office Products', selected: false },
    ],
  },
  {
    key: 'price',
    label: 'Price',
    items: [
      { value: '1', label: 'Under $25', selected: false },
      { value: '2', label: '$25 to $50', selected: false },
      { value: '3', label: '$50 to $100', selected: false },
      { value: '4', label: '$100 to $200', selected: false },
      { value: '5', label: 'Over $200', selected: false },
    ],
  },
  {
    key: 'brands',
    label: 'Brands',
    items: [
      { value: '1', label: 'Brand A', selected: true },
      { value: '2', label: 'Brand B', selected: true },
      { value: '3', label: 'Brand C', selected: false },
      { value: '4', label: 'Brand D', selected: true },
      { value: '5', label: 'Brand E', selected: true },
    ],
  },
  {
    key: 'rating',
    label: 'Rating',
    items: [
      { value: '1', label: '1 Star & Up', selected: true },
      { value: '2', label: '2 Stars & Up', selected: true },
      { value: '3', label: '3 Stars & Up', selected: true },
      { value: '4', label: '4 Stars & Up', selected: false },
      { value: '5', label: '5 Stars', selected: true },
    ],
  },
];

export const selectedItemsMock: SelectedFilterItem[] = [
  {
    value: '1',
    label: 'Red',
    hexColors: ['#ff0000'],
    selected: true,
    type: FilterOptionType.Color,

    category: 'colors',
  },
  {
    value: '2',
    label: 'Blue',
    hexColors: ['#0000ff'],
    selected: true,
    type: FilterOptionType.Color,
    category: 'colors',
  },
  { value: '3', label: '1 Star & Up', selected: true, category: 'rating' },
];
