/* istanbul ignore file */
import { useServiceUrls } from '@ori/configuration-manager';
import { useHeaders } from '@ori/presentation-http';
import { useCallback } from 'react';

import { getSearchResultsFacets } from '../../../api';
import { mapFacetFilterValuesToFacetQuery } from '../../../utils';
import { type FacetFilterValues } from '../../FacetFilter';

interface UseGetSearchResultsFacetsParams {
  query: string;
}
export const useGetSearchResultsFacets = ({ query }: UseGetSearchResultsFacetsParams) => {
  const { graphQlNetUrl } = useServiceUrls(['graphQlNet']);
  const { headers } = useHeaders();

  const getFacets = useCallback(
    async (values: FacetFilterValues[]) => {
      const response = await getSearchResultsFacets({
        graphQlNetUrl,
        headers,
        query,
        facetFiltering: mapFacetFilterValuesToFacetQuery(values),
      });

      return response;
    },
    [graphQlNetUrl, headers, query],
  );

  return { getFacets };
};
