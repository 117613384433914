import { CircularProgress, styled } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';

import { FACET_FILTER_LOADING } from '../constants';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(5px)',
  zIndex: 3,
});

export interface LoadingProps {
  isLoading?: boolean;
}
export const Loading = ({ isLoading }: LoadingProps) => {
  const { getTestId } = useTestId();
  if (!isLoading) {
    return null;
  }

  return (
    <Root data-testid={getTestId(FACET_FILTER_LOADING)}>
      <CircularProgress />
    </Root>
  );
};
