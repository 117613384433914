import { getTypedQueryParam } from '@ori/presentation-utils';

export const QUERY_NAME = 'query';

export const getSearchResultsQuery = () => {
  const query = getTypedQueryParam({
    name: QUERY_NAME,
  });

  return (query ?? '').trim();
};
