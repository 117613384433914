import { Skeleton } from '@ori-ui/mui';
import { PaginationSkeleton } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import React from 'react';

import { PAGINATION_LIMIT_ITEMS } from '../../constants';
import { Inner, Root, StyledDivider } from '../../styles';
import { Root as ActionsRoot } from '../Actions/styles';
import { Root as PaginationRoot } from '../BannerProductListingPagination/styles';
import { Root as ProductsCounterRoot } from '../ProductsCounter/styles';
import { ProductsLoading as ProductsSkeleton } from '../ProductsLoading';
import { BANNER_PRODUCT_LISTING_SKELETON, BANNER_PRODUCT_LISTING_SKELETON_DIVIDER } from './constants';
import { type BannerProductListingSkeletonProps } from './types';

export const BannerProductListingSkeleton = ({ hasBottomDivider = true }: BannerProductListingSkeletonProps) => {
  const { getTestId } = useTestId();

  return (
    <Root>
      <Inner data-testid={getTestId(BANNER_PRODUCT_LISTING_SKELETON)}>
        <ActionsRoot>
          <Skeleton
            variant="rounded"
            width="69px"
            height="24px"
            animation="pulse"
          />
          <Skeleton
            variant="rounded"
            width="137px"
            height="24px"
            animation="pulse"
          />
        </ActionsRoot>
        <ProductsCounterRoot>
          <Skeleton
            variant="rounded"
            width="88px"
            height="22px"
            animation="pulse"
          />
        </ProductsCounterRoot>
        <ProductsSkeleton items={PAGINATION_LIMIT_ITEMS} />
        <PaginationRoot>
          <PaginationSkeleton />
        </PaginationRoot>
        {hasBottomDivider ? <StyledDivider data-testid={getTestId(BANNER_PRODUCT_LISTING_SKELETON_DIVIDER)} /> : null}
      </Inner>
    </Root>
  );
};
