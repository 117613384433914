/* istanbul ignore file */
import { useServiceUrls } from '@ori/configuration-manager';
import type { EditorialPlpRowsFacetFilter } from '@ori/editorial-fetching';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports -- enum
import { PlpOrderBy } from '@ori/editorial-fetching';
import { useHeaders } from '@ori/presentation-http';
import { useCallback } from 'react';

import { PAGINATION_LIMIT_ROWS } from '../constants';
import { loadSearchResultsData } from './loadSearchResultsData';
import { mapSearchResultsQueryToContentItems } from './mapSearchResultsQueryToContentItems';

interface UseGetSearchResultsDataParams {
  query: string;
}

export interface GetSearchResultsDataParams {
  skip: number;
  limit?: number;
  pageId: string;
  orderBy: PlpOrderBy;
  facetFiltering?: EditorialPlpRowsFacetFilter[];
}
export const useGetSearchResultsData = ({ query }: UseGetSearchResultsDataParams) => {
  const { graphQlNetUrl } = useServiceUrls(['graphQlNet']);
  const { headers } = useHeaders();

  const getSearchResultsData = useCallback(
    async ({ skip, limit = PAGINATION_LIMIT_ROWS, orderBy, facetFiltering }: GetSearchResultsDataParams) => {
      const response = await loadSearchResultsData({
        limit,
        skip,
        query,
        orderBy,
        graphQlNetUrl,
        headers,
        facetFiltering,
      });

      return response ? mapSearchResultsQueryToContentItems(response) : undefined;
    },
    [graphQlNetUrl, headers, query],
  );

  return { getSearchResultsData };
};
