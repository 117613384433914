import type { FC } from 'react';

import { useBannerProductListingContext } from '../../../../contexts';
import { PAGINATION_LIMIT_ITEMS } from '../../constants';
import { ContentRows } from '../ContentRows';
import { ProductsLoading } from '../ProductsLoading';
import { Root } from './styles';

export const Content: FC = () => {
  const { content, sortLoading } = useBannerProductListingContext();

  return <Root>{sortLoading ? <ProductsLoading items={PAGINATION_LIMIT_ITEMS} /> : <ContentRows {...content} />}</Root>;
};
