import { Dialog } from '@ori-ui/components';
import { Button, Typography } from '@ori-ui/mui';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { ActionContainer, FilterDropdown, TitleContainer } from '../FilterDropdown';
import { FILTER_WINDOW_MOBILE_DIALOG } from './constants';
import type { FilterWindowProps } from './types';

export const FilterWindow: FC<PropsWithChildren<FilterWindowProps>> = ({
  isOpen,
  onClose,
  onClear,
  onSubmit,
  title,
  children,
  PaperProps,
  anchorEl,
  placement = 'bottom-start',
  settings,
  showTitle = true,
}) => {
  const { getTestId } = useTestId();
  const isMobile = useIsMobile();
  const header = useMemo(
    () =>
      showTitle ? (
        <TitleContainer>
          <Typography
            variant="h6"
            component="h6"
          >
            {title}
          </Typography>
        </TitleContainer>
      ) : null,
    [title, showTitle],
  );
  if (isMobile) {
    return (
      <Dialog
        data-testid={getTestId(FILTER_WINDOW_MOBILE_DIALOG)}
        open={isOpen}
        header={header}
        onClose={onClose}
      >
        <>
          {children}
          <ActionContainer>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={onSubmit}
            >
              {settings.submitButtonTitle}
            </Button>
            <Button
              fullWidth
              variant="text"
              onClick={onClear}
            >
              {settings.clearButtonTitle}
            </Button>
          </ActionContainer>
        </>
      </Dialog>
    );
  }

  return (
    <FilterDropdown
      isOpen={isOpen}
      title={title}
      PaperProps={PaperProps}
      anchorEl={anchorEl}
      placement={placement}
      showTitle={showTitle}
      settings={settings}
      onClose={onClose}
      onClear={onClear}
      onSubmit={onSubmit}
    >
      {children}
    </FilterDropdown>
  );
};
