/* istanbul ignore file */
import {
  searchResultsFacetsQuery,
  type SearchResultsFacetsQuery,
  type SearchResultsFacetsQueryVariables,
} from '@ori/editorial-fetching';
import { fetchGraphql } from '@ori/fetching';

import { type FacetFilterValues } from '../components/FacetFilter/types';
import { mapFacetFilterFromApi } from '../utils/mapFacetFilterFromApi';
import type { GetSearchResultsFacetsParams } from './types';

/**
 * Fetch function that fetches facets for a given query and facetFiltering keys
 */
export const getSearchResultsFacets = async ({
  graphQlNetUrl,
  headers,
  query,
  facetFiltering,
}: GetSearchResultsFacetsParams): Promise<FacetFilterValues[]> => {
  const data = await fetchGraphql<SearchResultsFacetsQuery, SearchResultsFacetsQueryVariables>({
    query: searchResultsFacetsQuery,
    url: graphQlNetUrl,
    variables: {
      query,
      facetFiltering,
    },
    headers,
    timeout: 10000,
  });

  return mapFacetFilterFromApi(data.application?.productSearchPage?.products?.facets);
};
