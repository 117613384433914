import { styled } from '@ori-ui/mui';
import { ProductCarousel } from '@ori/editorial-page';
import { OlapicWidget } from '@ori/olapic-lib';

export const EditorialBannersRoot = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 'auto'),
  display: 'flex',
  flexDirection: 'column',
}));

export const EditorialRoot = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'block',
}));

export const CarouselRoot = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'block',
}));

export const CarouselInner = styled('div')(({ theme }) => ({
  maxWidth: '1078px',
  margin: theme.spacing(0, 'auto'),
}));

export const EditorialCarouselSectionRoot = styled('div')(({ theme }) => ({
  maxWidth: '1078px',
  margin: theme.spacing(0, 'auto'),
}));

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8),
  },
}));

export const styledProductCarouselRoot = 'product-carousel__root';
export const StyledProductCarousel = styled(ProductCarousel)(({ theme }) => ({
  margin: 0,
  [`.${styledProductCarouselRoot}`]: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(12),
    },
  },
}));

export const StyledOlapicWidget = styled(OlapicWidget)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
}));
