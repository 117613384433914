/* istanbul ignore file */
import type { ClientServiceUrls } from '@ori/configuration-manager';
import type {
  EditorialPlpRowsFacetFilter,
  SearchResultsPageQuery,
  SearchResultsPageQueryVariables,
} from '@ori/editorial-fetching';
import { searchResultsPageQuery } from '@ori/editorial-fetching';
import type { Headers } from '@ori/fetching';
import { fetchGraphql } from '@ori/fetching';
import type { PlpOrderBy } from '@ori/graphql-schema';

import { mainLogger } from '../../../logger';

const localLogger = mainLogger.createChild('loadSearchResultsData');

interface LoadSearchResultsData extends Required<Pick<ClientServiceUrls, 'graphQlNetUrl'>> {
  limit: number;
  skip: number;
  query: string;
  orderBy: PlpOrderBy;
  facetFiltering?: EditorialPlpRowsFacetFilter[];
  headers: Headers;
}

export const loadSearchResultsData = async ({
  headers,
  orderBy,
  query,
  skip,
  graphQlNetUrl,
  limit,
  facetFiltering = [],
}: LoadSearchResultsData) => {
  try {
    return await fetchGraphql<SearchResultsPageQuery, SearchResultsPageQueryVariables>({
      query: searchResultsPageQuery,
      variables: {
        limit,
        orderBy,
        query,
        skip,
        facetFiltering,
      },
      url: graphQlNetUrl,
      headers,
      timeout: 10000,
    });
  } catch (error) {
    localLogger.error(new Error('fetchGraphql searchResultsPageQuery', { cause: error }));
  }

  return undefined;
};
