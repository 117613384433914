import { useState, useEffect, useRef } from 'react';

export const MINIMUM_DELAY = 750;

interface UseDelayedLoadingParams {
  delay?: number;
  loading: boolean;
}
/**
 * Hook to ensure that the loading state lasts for at least a certain amount of time.
 * This is useful to avoid flickering loading indicators.
 *
 * @param delay The minimum time to show the loading indicator, default value is 750 (ms). When set to 0, fake loading will not be used.
 * @param loading The loading state
 *
 * @returns showLoading A boolean indicating whether the loading indicator should be shown
 *
 * @example
 * const { isLoading } = useDelayedLoading({ loading });
 *
 */
export const useDelayedLoading = ({ delay = MINIMUM_DELAY, loading }: UseDelayedLoadingParams) => {
  const [fakeLoading, setFakeLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (loading && delay > 0) {
      setFakeLoading(true);
      timeoutRef.current = setTimeout(() => {
        setFakeLoading(false);
      }, delay);
    }
  }, [delay, loading]);

  // Clear the timeout when the component is unmounted
  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  return {
    isLoading: fakeLoading || loading,
  };
};
