import { Typography } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { SEARCH_RESULTS_HEADER } from './constants';
import { Query, QuerySkeleton, Root } from './styles';
import type { SearchResultsHeaderProps } from './types';

/**
 * Search results header component. This component is used to display the search query.
 */
export const SearchResultsHeader: FC<SearchResultsHeaderProps> = ({ query, className, loaded }) => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();

  return (
    <Root
      data-testid={getTestId(SEARCH_RESULTS_HEADER)}
      className={className}
    >
      <Typography
        fontWeight="bold"
        variant="h5"
        component="h5"
        align="center"
        color="textPrimary"
      >
        {translations.showingResultsFor}
      </Typography>
      {loaded ? (
        <Query
          align="center"
          color="textPrimary"
        >
          {`”${query ?? ''}”`}
        </Query>
      ) : (
        <QuerySkeleton />
      )}
    </Root>
  );
};
