import { useLogger } from '@ori/logger';
import { useCallback, useEffect, useState } from 'react';

import { useBannerProductListingContext } from '../../../../contexts';
import { useLoadDataAndUpdateState } from '../../../../contexts/utils';
import { CLIENT_PAGINATION_LIMIT_ROWS } from '../../constants';
import { ActionTypeEnum } from '../../types';

interface UseFetchInitialPLPRows {
  enabled: boolean;
}
export const useFetchInitialPLPRows = ({ enabled }: UseFetchInitialPLPRows) => {
  const { appliedFacets, orderBy, skip } = useBannerProductListingContext();
  const { loadDataAndUpdateState } = useLoadDataAndUpdateState();
  const localLogger = useLogger('useFetchInitialPLPRows');
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const onLoadMore = useCallback(
    (limit?: number) => {
      loadDataAndUpdateState({
        orderByValue: orderBy,
        skipValue: skip,
        limit,
        action: ActionTypeEnum.LoadMore,
        facetFiltering: appliedFacets,
      }).catch((error) => localLogger.error(new Error('loadDataAndUpdateState', { cause: error }), { orderBy, skip }));
    },
    [loadDataAndUpdateState, orderBy, skip, appliedFacets, localLogger],
  );
  useEffect(() => {
    if (enabled && !initialFetchDone) {
      setInitialFetchDone(true);
      onLoadMore(CLIENT_PAGINATION_LIMIT_ROWS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this effect once when enabled is true
  }, [enabled]);
};
