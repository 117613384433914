import type { CategoryBarFragment } from '@ori/editorial-fetching';
import type { CategoryLink } from '@ori/presentation-components';

export const mapCategoryBarFromPageNavigation = (
  categoryBarData?: CategoryBarFragment | null | undefined,
): CategoryLink[] | null => {
  const items = categoryBarData?.items ?? [];

  return items.map((item) => ({
    id: item?.itemId ?? '',
    title: item?.label ?? '',
    relativeUrl: item?.url ?? '',
  }));
};
