/* istanbul ignore file */
import { useServiceUrls } from '@ori/configuration-manager';
import type { ProductListingRowsFragment, SearchResultsPageQuery } from '@ori/editorial-fetching';
import { PlpOrderBy } from '@ori/editorial-fetching';
import { useSWR } from '@ori/fetching';
import { useHeaders } from '@ori/presentation-http';
import { isSSR } from '@ori/presentation-utils';
import { useMemo } from 'react';

import { SERVER_PAGINATION_LIMIT_ROWS } from '../constants';
import { loadSearchResultsData } from './loadSearchResultsData';
import { mapSearchResultsPageQueryToProductListingRows } from './mapSearchResultsPageQueryToProductListingRows';

interface UseGetInitialSearchResultsDataParams {
  query: string;
}
interface UseGetInitialSearchResultsDataResult {
  loaded: boolean;
  data?: ProductListingRowsFragment;
}
export const useGetInitialSearchResultsData = ({
  query,
}: UseGetInitialSearchResultsDataParams): UseGetInitialSearchResultsDataResult => {
  const { graphQlNetUrl } = useServiceUrls(['graphQlNet']);
  const { headers, loading } = useHeaders();

  const { data, error } = useSWR<SearchResultsPageQuery | undefined, Error>(
    loading || !query ? null : [query, headers],
    async () =>
      loadSearchResultsData({
        graphQlNetUrl,
        headers,
        limit: SERVER_PAGINATION_LIMIT_ROWS,
        orderBy: PlpOrderBy.Recommended,
        query,
        skip: 0,
        facetFiltering: [],
      }),
    { dedupingInterval: 30000, revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false },
  );

  const result: UseGetInitialSearchResultsDataResult = useMemo(
    () => ({
      loaded: !!data || !!error || (!isSSR() && !query),
      data: data ? mapSearchResultsPageQueryToProductListingRows(data) : undefined,
    }),
    [data, error, query],
  );

  return result;
};
