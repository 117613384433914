import { type Headers } from '@ori/fetching';
import isEqual from 'lodash.isequal';
import type { Dispatch, SetStateAction } from 'react';
import { useState, useCallback, useMemo } from 'react';

import { useBannerProductListingContext } from '../../../contexts/bannerProductListingContext';
import { useDelayedLoading } from '../../../utils/useDelayedLoading';
import type { FacetFilterValues } from '../../FacetFilter';

export interface UserFilterParams {
  initialValue: FacetFilterValues[];
  loadData: (facetFiltering: FacetFilterValues[]) => Promise<void>;
  headers: Headers;
  pageId: string;
  appliedFacets: FacetFilterValues[];
  setAppliedFacets: Dispatch<SetStateAction<FacetFilterValues[]>>;
}

// Just for encapsulation of logic for filter component on PLP
export const useFilter = ({ initialValue, loadData, appliedFacets, setAppliedFacets }: UserFilterParams) => {
  const { getFacets } = useBannerProductListingContext();

  // this is value for filter component
  const [facets, setFacets] = useState<FacetFilterValues[]>(initialValue);
  const [loading, setLoading] = useState(false);
  const { isLoading } = useDelayedLoading({
    loading,
  });
  /**
   * Used for displaying selected facets in filter container
   * It is different than appliedFacets, because appliedFacets can contain facets that are not selected
   */
  const selectedFacets = useMemo(
    () =>
      appliedFacets.flatMap((facet) =>
        facet.items.filter((item) => item.selected).map((item) => ({ ...item, category: facet.key })),
      ),
    [appliedFacets],
  );

  const onFilterChange = useCallback(
    async (values: FacetFilterValues[]) => {
      setFacets(values);
      setLoading(true);
      const newFacets = await getFacets(values);

      setFacets(newFacets);
      setLoading(false);
    },
    [getFacets],
  );

  const onFilterApply = useCallback(
    async (value: FacetFilterValues[]) => {
      await loadData(value);
      setAppliedFacets(value);
    },
    [loadData, setAppliedFacets],
  );

  const onClear = useCallback(async () => {
    const updatedValue = appliedFacets.map((facet) => ({
      ...facet,
      items: facet.items.map((item) => ({ ...item, selected: false })),
    }));
    setAppliedFacets(updatedValue);
    await onFilterChange(updatedValue);
    await loadData([]);
  }, [onFilterChange, loadData, appliedFacets, setAppliedFacets]);

  const onFilterClose = useCallback(async () => {
    /**
     * When user clicks on close button, we need to reset filter facets to currently applied facets.
     * This is because user can change filter facets, but not apply them.
     */
    if (!isEqual(facets, appliedFacets)) {
      await onFilterChange(appliedFacets);
    }
  }, [appliedFacets, facets, onFilterChange]);

  const onFacetFilterClear = useCallback(
    async (key: string, category: string) => {
      const updatedValue = appliedFacets.map((facet) =>
        facet.key === category
          ? {
              ...facet,
              items: facet.items.map((item) => (item.value === key ? { ...item, selected: false } : item)),
            }
          : facet,
      );
      setAppliedFacets(updatedValue);
      await onFilterChange(updatedValue);
      await onFilterApply(updatedValue);
    },
    [appliedFacets, onFilterApply, onFilterChange, setAppliedFacets],
  );

  return {
    onFilterApply,
    onFilterChange,
    onClear,
    selectedFacets,
    onFilterClose,
    onFacetFilterClear,
    filterValue: facets,
    appliedFacets,
    setAppliedFacets,
    loading: isLoading,
  };
};
