import { styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { FacetFilterCategoryProps } from '../FacetFilterCategory';

export const Root = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<Pick<FacetFilterCategoryProps, 'isFirst'>>(({ isFirst = false, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: isFirst ? theme.spacing(1, 0, 3, 0) : theme.spacing(3, 0),
  width: '100%',
  justifyContent: 'space-between',
  cursor: 'pointer',
  userSelect: 'none',
}));

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  maxWidth: 'calc(100% - 55px)',
  wordBreak: 'break-word',
  hyphens: 'auto',
}));

export const TitleLayout = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}));

export const IconContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(3),
}));
