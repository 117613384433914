import { Button } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import { type FC, useCallback } from 'react';

import { FilterOption } from '../FilterOption';
import { isColorOption } from '../FilterOption/utils/isColorOption';
import { FILTERS_CONTAINER, FILTERS_CONTAINER_CLEAR_BUTTON, labelProps, optionProps } from './constants';
import { ButtonContainer, ItemsContainer, Root } from './styles';
import type { FiltersContainerProps } from './types';

export const FiltersContainer: FC<FiltersContainerProps> = ({
  onClear,
  onDelete,
  settings,
  selectedItems: items,
  isLoading = false,
}) => {
  const { getTestId } = useTestId();

  const onDeleteInternal = useCallback(
    (key: string, category: string) => () => {
      onDelete(key, category);
    },
    [onDelete],
  );

  return (
    <Root data-testid={getTestId(FILTERS_CONTAINER)}>
      <ItemsContainer>
        {items.map((item) => (
          <FilterOption
            key={item.value}
            label={item.label}
            selected={false}
            value={item.value}
            type={item.type}
            LabelProps={labelProps}
            OptionProps={optionProps}
            hexColors={isColorOption(item) ? item.hexColors : undefined}
            onDelete={isLoading ? undefined : onDeleteInternal(item.value, item.category)}
          />
        ))}
      </ItemsContainer>
      <ButtonContainer>
        <Button
          data-testid={getTestId(FILTERS_CONTAINER_CLEAR_BUTTON)}
          variant="outlined"
          color="default"
          disabled={isLoading}
          onClick={onClear}
        >
          {settings.clearButtonTitle}
        </Button>
      </ButtonContainer>
    </Root>
  );
};
