import { Typography, styled } from '@ori-ui/mui';

import { UnifiedSkeleton } from '../styles';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const Query = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: theme.typography.fontWeightRegular,
}));

export const QuerySkeleton = styled(UnifiedSkeleton)(() => ({
  width: 115,
  height: '3rem',
}));
