import { useLogger } from '@ori/logger';
import { Pagination } from '@ori/presentation-components';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { OnEnter } from 'react-cool-inview';
import { useInView } from 'react-cool-inview';

import { useBannerProductListingContext } from '../../../../contexts';
import { useLoadDataAndUpdateState } from '../../../../contexts/utils';
import { useTranslations } from '../../../../hooks/useTranslations';
import { PAGINATION_LIMIT_ITEMS } from '../../constants';
import { ActionTypeEnum } from '../../types';
import type { BannerProductListingProps } from '../../types';
import { useFetchInitialPLPRows } from './hooks';
import { Root } from './styles';

// istanbul ignore next -- no need to test unobserve.
const onEnter: OnEnter = ({ unobserve }) => unobserve();

export const BannerProductListingPagination: FC<BannerProductListingProps> = () => {
  const { translations, formatMessage } = useTranslations();
  const localLogger = useLogger('BannerProductListingPagination');
  const { content, appliedFacets, orderBy, skip, paginationLoading } = useBannerProductListingContext();
  const { loadDataAndUpdateState } = useLoadDataAndUpdateState();
  const numberOfLoadedProducts = useMemo(
    () =>
      content?.rows?.reduce(
        (acc, item) =>
          acc + (item?.content?.filter((contentItem) => contentItem?.__typename === 'Product').length ?? 0),
        0,
      ) ?? 0,
    [content?.rows],
  );
  const initialNumberOfLoadedBanners = useMemo(
    () =>
      content?.rows?.reduce(
        (acc, item) =>
          acc +
          (item?.content?.filter((contentItem) => contentItem?.__typename === 'EditorialPlpPromoBanner').length ?? 0),
        0,
      ) ?? 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't need to recalculate this value
    [],
  );
  const totalProductsCount = content?.totalProductsCount ?? 0;
  const totalBannersCount = content?.totalBannersCount ?? 0;
  const paginationProgress = (numberOfLoadedProducts / totalProductsCount) * 100;
  const paginationEnd = numberOfLoadedProducts < totalProductsCount;

  const onLoadMore = useCallback(() => {
    loadDataAndUpdateState({
      orderByValue: orderBy,
      skipValue: skip,
      action: ActionTypeEnum.LoadMore,
      facetFiltering: appliedFacets,
    }).catch((error) => localLogger.error(new Error('loadDataAndUpdateState', { cause: error }), { orderBy, skip }));
  }, [loadDataAndUpdateState, orderBy, skip, appliedFacets, localLogger]);

  const { inView, observe } = useInView({ rootMargin: '100px', onEnter });
  useFetchInitialPLPRows({ enabled: inView });
  if (
    totalProductsCount + totalBannersCount < PAGINATION_LIMIT_ITEMS &&
    initialNumberOfLoadedBanners === totalBannersCount
  ) {
    return null;
  }

  return (
    <Root ref={observe}>
      {totalProductsCount > 1 ? (
        <Pagination
          hideProgress={paginationEnd}
          button={{
            label: translations.showMore,
            loading: paginationLoading,
            onClick: onLoadMore,
            visible: paginationEnd,
          }}
          infoText={formatMessage('showingXOutOfYProducts', {
            value: numberOfLoadedProducts,
            total: totalProductsCount,
          })}
          progress={paginationProgress}
        />
      ) : null}
    </Root>
  );
};
