import { type PlpFacetValue } from '@ori/graphql-schema';

import type { FilterItem } from '../components/FilterOption';
import { FilterOptionType } from '../components/FilterOption';

export const getFacetItemFromApi = (facet: PlpFacetValue | null): FilterItem => {
  if (!facet) {
    return {
      label: '',
      selected: false,
      value: '',
      type: FilterOptionType.Default,
    };
  }

  return {
    label: facet.displayName ?? '',
    selected: facet.active ?? false,
    value: facet.key ?? '',
    type: facet.facetInfo?.color ? FilterOptionType.Color : FilterOptionType.Default,
    hexColors: facet.facetInfo?.color ? [facet.facetInfo.color] : undefined,
  };
};
