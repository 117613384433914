/* istanbul ignore file */
import type { ProductListingFacetsQuery, ProductListingFacetsQueryVariables } from '@ori/editorial-fetching/src/models';
import { productListingFacetsQuery } from '@ori/editorial-fetching/src/queries/editorialFacetsQuery';
import { fetchGraphql } from '@ori/fetching';

import { type FacetFilterValues } from '../components/FacetFilter/types';
import { mapFacetFilterFromApi } from '../utils/mapFacetFilterFromApi';
import type { GetProductListingFacetsParams } from './types';

/**
 * Fetch function that fetches facets for a given pageId and facetFiltering keys
 */
export const getProductListingFacets = async ({
  graphQlUrl,
  headers,
  pageId,
  facetFiltering,
}: GetProductListingFacetsParams): Promise<FacetFilterValues[]> => {
  const data = await fetchGraphql<ProductListingFacetsQuery, ProductListingFacetsQueryVariables>({
    query: productListingFacetsQuery,
    url: graphQlUrl,
    variables: {
      pageId,
      facetFiltering,
    },
    headers,
    timeout: 10000,
  });

  const contentItems = data.application?.editorialPage?.contentItems ?? [];
  const editorialPlpRow = contentItems.find((item) => item?.__typename === 'EditorialPlpRows');
  if (editorialPlpRow?.__typename === 'EditorialPlpRows') {
    return mapFacetFilterFromApi(editorialPlpRow.facets);
  }

  return [];
};
