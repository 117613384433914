/* istanbul ignore file */
import { useToken } from '@ori/auth';
import { useEnvironment } from '@ori/environment';
import type { TranslationsContext } from '@ori/i18n';
import { TranslationsNamespaceProvider, useLanguage } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import type { UserSegmentationDataProviderProps } from '@ori/presentation-http';
import { HeadersProvider, UserSegmentationDataProvider } from '@ori/presentation-http';
import { Team, TestIdProvider } from '@ori/testid-generator';
import type { FC } from 'react';

import { APPLICATION_NAME, APP_NAME, translationsNamespace } from '../../constants';
import { ProductListingProvider } from '../../contexts';
import { mainLogger } from '../../logger';
import { ApiProvider } from '../ApiProvider';
import { ProductListingPage } from '../ProductListingPage/ProductListingPage';
import type { ProductListingPageProps } from '../ProductListingPage/types';

export interface ProductListingPageContainerProps
  extends ProductListingPageProps,
    Omit<UserSegmentationDataProviderProps, 'visitorSegment'>,
    Pick<TranslationsContext, 'language'> {
  hideRating: boolean;
}
export const ProductListingPageContainer: FC<ProductListingPageContainerProps> = ({
  atpClusterId,
  customerTypeId,
  data,
  pageId,
  hideRating,
}) => {
  const { language } = useLanguage();
  const { tenant } = useEnvironment();
  const { token } = useToken();

  return (
    <TestIdProvider
      team={Team.Presentation}
      project={APP_NAME}
    >
      <LoggerProvider logger={mainLogger}>
        <ErrorBoundary areaName="ProductListingPageContainer">
          <TranslationsNamespaceProvider namespace={translationsNamespace}>
            <ApiProvider>
              <UserSegmentationDataProvider
                atpClusterId={atpClusterId}
                customerTypeId={customerTypeId}
              >
                <HeadersProvider
                  language={language}
                  sourceAppName={APPLICATION_NAME}
                  tenant={tenant}
                  token={token}
                >
                  <ProductListingProvider hideRating={hideRating}>
                    <ProductListingPage
                      pageId={pageId}
                      data={data}
                    />
                  </ProductListingProvider>
                </HeadersProvider>
              </UserSegmentationDataProvider>
            </ApiProvider>
          </TranslationsNamespaceProvider>
        </ErrorBoundary>
      </LoggerProvider>
    </TestIdProvider>
  );
};
