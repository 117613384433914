import type { EditorialPlpRowsFacetFilter } from '@ori/editorial-fetching';

import { type FacetFilterValues } from '../components/FacetFilter';

/**
 * Transforms the facets data from the FacetFilter component into a format suitable for the API query.
 *
 * @param facets - An array of FacetFilterValues, each representing a facet filter category with its label, key, and items.
 * Each item represents a filter option with its label, selection state, value, and type.
 * @returns An array of EditorialPlpRowsFacetFilter, each representing a facet filter with its name and keys.
 * Each key represents a selected filter option.
 *
 * Note: Only the selected filter options are included in the keys of the returned facet filters.
 * Also, the returned array does not include any facet filters that have no selected options.
 */
export const mapFacetFilterValuesToFacetQuery = (
  facets: FacetFilterValues[] | undefined,
): EditorialPlpRowsFacetFilter[] =>
  facets
    ?.map((facet) => ({
      name: facet.key,
      keys: facet.items.filter((item) => item.selected).map((item) => item.value),
    }))
    .filter((facet) => facet.keys.length > 0) ?? [];
