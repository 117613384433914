import type { TypographyProps } from '@ori-ui/mui';
import type { FC } from 'react';

import type { FilterItemDefault } from '../types';
import { BaseOption } from './BaseOption';

interface DefaultOptionProps extends Pick<FilterItemDefault, 'label' | 'selected'> {
  onClick?: () => void;
  onDelete?: () => void;
  LabelProps?: TypographyProps;
  OptionProps?: {
    height?: number;
  };
}
export const DefaultOption: FC<DefaultOptionProps> = ({
  selected,
  label,
  onClick,
  onDelete,
  LabelProps,
  OptionProps,
}) => (
  <BaseOption
    variant="rounded"
    label={label}
    selected={selected}
    LabelProps={LabelProps}
    height={OptionProps?.height}
    onClick={onClick}
    onDelete={onDelete}
  />
);
