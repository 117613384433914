import { theme } from '@ori-ui/mui';
import { CategoryBar, TopBanner } from '@ori/presentation-components';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';
import React, { useMemo } from 'react';

import { mapBreadcrumbsFromBreadcrumbLinks, mapCategoryBarFromPageNavigation } from '../../utils';
import { PLP_HEADER_AREA } from './constants';
import { Inner, IntroTextStyled, Root } from './styles';
import type { HeaderAreaProps } from './types';

export const HeaderArea = ({ banner, pageNavigation, breadcrumbs }: HeaderAreaProps) => {
  const { getTestId } = useTestId();
  const mappedCategoryBarItems = useMemo(() => mapCategoryBarFromPageNavigation(pageNavigation), [pageNavigation]);
  const mappedBreadcrumbs = useMemo(() => mapBreadcrumbsFromBreadcrumbLinks(breadcrumbs), [breadcrumbs]);

  return (
    <Root data-testid={getTestId(PLP_HEADER_AREA)}>
      <Inner>
        <TopBanner
          key={banner.headline}
          showTitleOnImageBackground
          title={banner.headline}
          breadcrumbs={mappedBreadcrumbs}
          backgroundColor={mapBackendColorToTheme({ backendColor: banner.backgroundStorybookColor, theme })}
          textColor={mapBackendColorToTheme({ backendColor: banner.headlineStorybookColor, theme })}
          imageUrl={banner.imageUrl}
          extraLongHeadline={banner.extraLongHeadline}
        />
        {mappedCategoryBarItems && mappedCategoryBarItems.length > 0 ? (
          <CategoryBar
            // Font Color should be the same as background color of the banner but it can be not visible if set incorrectly. Leaving default value
            // fontColor={mapBackendColorToTheme({ backendColor: item.backgroundStorybookColor, theme })}
            activeLink={mappedCategoryBarItems[0]}
            linkData={mappedCategoryBarItems}
          />
        ) : null}
      </Inner>
      {banner.text ? <IntroTextStyled text={banner.text} /> : null}
    </Root>
  );
};
