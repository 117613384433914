import { PlpOrderBy, type ProductListingRowsFragment } from '@ori/editorial-fetching';
import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import { type ContentItems } from '../components';
import { PAGINATION_LIMIT_ROWS, SERVER_PAGINATION_LIMIT_ROWS } from '../components/BannerProductListing/constants';
import { type GetProductListingDataParams } from '../components/BannerProductListing/utils/useGetProductListingData';
import type { FacetFilterValues } from '../components/FacetFilter';

export interface BannerProductListingContext {
  data?: ProductListingRowsFragment | null | undefined;
  pageId: string;
  setContent: Dispatch<SetStateAction<ProductListingRowsFragment | null | undefined>>;
  content: ProductListingRowsFragment | null | undefined;
  skip: number;
  setSkip: Dispatch<SetStateAction<number>>;
  paginationLoading: boolean;
  setPaginationLoading: Dispatch<SetStateAction<boolean>>;
  isPLP?: boolean | undefined;
  sortLoading: boolean;
  setSortLoading: Dispatch<SetStateAction<boolean>>;
  appliedFacets: FacetFilterValues[];
  setAppliedFacets: Dispatch<SetStateAction<FacetFilterValues[]>>;
  orderBy: PlpOrderBy;
  setOrderBy: Dispatch<SetStateAction<PlpOrderBy>>;
  getFacets: (values: FacetFilterValues[]) => Promise<FacetFilterValues[]>;
  getData: (params: GetProductListingDataParams) => Promise<ContentItems | undefined>;
}

// istanbul ignore next -- No need to test default values.
export const defaultBannerProductListingContextValues: BannerProductListingContext = {
  data: null,
  pageId: '',
  setContent: () => {},
  content: null,
  skip: PAGINATION_LIMIT_ROWS,
  setSkip: () => {},
  paginationLoading: false,
  setPaginationLoading: () => {},
  setSortLoading: () => {},
  sortLoading: false,
  appliedFacets: [],
  setAppliedFacets: () => {},
  orderBy: PlpOrderBy.Recommended,
  setOrderBy: () => {},
  getFacets: async () => [],
  getData: async () => undefined,
};

export const BannerproductListingContext = createContext<BannerProductListingContext>(
  defaultBannerProductListingContextValues,
);

const { Provider } = BannerproductListingContext;

export interface BannerProductListingStoreProviderProps extends PropsWithChildren {
  data?: ProductListingRowsFragment | null | undefined;
  isPLP?: boolean | undefined;
  pageId?: string;
  getFacets: (values: FacetFilterValues[]) => Promise<FacetFilterValues[]>;
  getData: (params: GetProductListingDataParams) => Promise<ContentItems | undefined>;
}

export const BannerProductListingProvider: FC<BannerProductListingStoreProviderProps> = ({
  children,
  isPLP,
  pageId,
  data,
  getFacets,
  getData,
}) => {
  const [content, setContent] = useState<ProductListingRowsFragment | null | undefined>(data);
  const [skip, setSkip] = useState(SERVER_PAGINATION_LIMIT_ROWS);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [appliedFacets, setAppliedFacets] = useState<FacetFilterValues[]>([]);
  const [orderBy, setOrderBy] = useState<PlpOrderBy>(PlpOrderBy.Recommended);

  const value = useMemo(
    () => ({
      data,
      pageId: pageId ?? '',
      setContent,
      content,
      skip,
      setSkip,
      paginationLoading,
      setPaginationLoading,
      isPLP,
      sortLoading,
      setSortLoading,
      appliedFacets,
      setAppliedFacets,
      orderBy,
      setOrderBy,
      getFacets,
      getData,
    }),
    [
      data,
      pageId,
      setContent,
      content,
      skip,
      setSkip,
      paginationLoading,
      setPaginationLoading,
      isPLP,
      sortLoading,
      setSortLoading,
      appliedFacets,
      setAppliedFacets,
      orderBy,
      setOrderBy,
      getFacets,
      getData,
    ],
  );

  return <Provider value={value}>{children}</Provider>;
};

export const useBannerProductListingContext = () => useContext(BannerproductListingContext);
