import type { Theme } from '@ori-ui/mui';

interface GetColorBackground {
  hexColors?: string[];
  imageUrl?: string;
  theme: Theme;
}
export const getColorBackground = ({ hexColors, imageUrl, theme }: GetColorBackground) => {
  if (hexColors?.length === 1 && hexColors[0]) {
    return hexColors[0];
  }
  if (hexColors && hexColors.length > 1) {
    return `linear-gradient(to right, ${hexColors[0]}, ${hexColors[0]} 50%, ${hexColors[1]} 50%)`;
  }
  if (imageUrl) {
    return `url(${imageUrl})`;
  }

  return theme.palette.common.white;
};
