import useTestId from '@ori/testid-generator';
import { useCallback, useMemo, type FC } from 'react';

import { FacetFilterHeader } from '../FacetFilterHeader';
import { FilterOption } from '../FilterOption';
import { isColorOption } from '../FilterOption/utils/isColorOption';
import { FACET_FILTER_CATEGORY } from './constants';
import { Category, Container } from './styles';
import type { FacetFilterCategoryProps } from './types';

export const FacetFilterCategory: FC<FacetFilterCategoryProps> = ({
  category,
  openedCategory,
  onCategoryClick,
  onOptionClick,
  categoryRef,
  isFirst,
}) => {
  const { getTestId } = useTestId();
  const isSelectedCategory = openedCategory === category.key;
  const selectedItemsCount = useMemo(() => category.items.filter((item) => item.selected).length, [category.items]);

  const onCategoryClickInternal = useCallback(() => {
    onCategoryClick(category.key);
  }, [category.key, onCategoryClick]);

  const onOptionClickInternal = useCallback(
    (value: string) => () => {
      onOptionClick(category.key, value);
    },
    [category.key, onOptionClick],
  );

  return (
    <Category
      key={category.key}
      ref={categoryRef}
      data-testid={getTestId(`${FACET_FILTER_CATEGORY}-${category.key}`)}
    >
      <FacetFilterHeader
        title={category.label}
        isOpen={isSelectedCategory}
        amountTotal={category.items.length}
        amountSelected={selectedItemsCount}
        isFirst={isFirst}
        onClick={onCategoryClickInternal}
      />
      {isSelectedCategory ? (
        <Container>
          {category.items.map((item) => (
            <FilterOption
              key={item.value}
              label={item.label}
              selected={item.selected}
              value={item.value}
              type={item.type}
              hexColors={isColorOption(item) ? item.hexColors : undefined}
              onClick={onOptionClickInternal(item.value)}
            />
          ))}
        </Container>
      ) : null}
    </Category>
  );
};
