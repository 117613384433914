/* istanbul ignore file */
import { useServiceUrls } from '@ori/configuration-manager';
import { useHeaders } from '@ori/presentation-http';
import { useCallback } from 'react';

import { getProductListingFacets } from '../../../api';
import { mapFacetFilterValuesToFacetQuery } from '../../../utils';
import { type FacetFilterValues } from '../../FacetFilter';

interface UseGetProductListingFacetsParams {
  pageId: string;
}
export const useGetProductListingFacets = ({ pageId }: UseGetProductListingFacetsParams) => {
  const { graphQlUrl } = useServiceUrls(['graphQl']);
  const { headers } = useHeaders();

  const getFacets = useCallback(
    async (values: FacetFilterValues[]) => {
      const response = await getProductListingFacets({
        graphQlUrl,
        headers,
        pageId,
        facetFiltering: mapFacetFilterValuesToFacetQuery(values),
      });

      return response;
    },
    [graphQlUrl, headers, pageId],
  );

  return { getFacets };
};
