/* istanbul ignore file */
import type { SearchResultsPageQuery } from '@ori/editorial-fetching';

import type { ContentItems } from '../types';
import { mapSearchResultsPageQueryToProductListingRows } from './mapSearchResultsPageQueryToProductListingRows';

export const mapSearchResultsQueryToContentItems = (query: SearchResultsPageQuery): ContentItems => {
  const rows = mapSearchResultsPageQueryToProductListingRows(query);

  return rows
    ? [
        {
          __typename: 'EditorialPlpRows',
          ...rows,
        },
      ]
    : [];
};
