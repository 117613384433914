import type { CSSObject } from '@ori-ui/mui';
import { styled, Chip, chipClasses } from '@ori-ui/mui';

import { COLOR_ICON_SIZE, OPTION_HEIGHT, SELECTED_COLOR_ICON_SIZE } from './constants';
import type { BaseOptionProps } from './optionTypes/types';
import { getColorBackground } from './utils/getColorBackground';
import { getColorBorder } from './utils/getColorBorder';
import { getColorBoxShadow } from './utils/getColorBoxShadow';

export const StyledBaseChip = styled(Chip)<BaseOptionProps>((props) => ({
  height: props.height ?? OPTION_HEIGHT,
  ...(props.selected && {
    '&&': {
      backgroundColor: props.theme.palette.success.main,
      [`.${chipClasses.label}`]: {
        color: props.theme.palette.success.contrastText,
      },
    },
  }),
  [`&.${chipClasses.deletable}`]: {
    paddingRight: props.theme.spacing(1),
  },
}));

interface ColorProps {
  selected?: boolean;
  hexColors?: string[];
  iconSize?: number;
  selectedIconSize?: number;
}
export const Color = styled('div')<ColorProps>(({ selected, hexColors, theme, iconSize, selectedIconSize }) => {
  const background = getColorBackground({
    hexColors,
    theme,
  });
  const border = getColorBorder({
    background,
    theme,
  });
  const style: CSSObject = {
    background,
    backgroundSize: 'cover',
    border,
    borderRadius: '50%',
    boxSizing: 'border-box',
    display: 'flex',
    height: iconSize ?? COLOR_ICON_SIZE,
    position: 'relative',
    width: iconSize ?? COLOR_ICON_SIZE,
  };
  if (selected) {
    style.height = selectedIconSize ?? SELECTED_COLOR_ICON_SIZE;
    style.width = selectedIconSize ?? SELECTED_COLOR_ICON_SIZE;
    style.border = `1px solid ${theme.palette.common.black}`;
    style['&::before'] = {
      border: '0px solid transparent',
      borderRadius: '50%',
      boxShadow: `inset 0 0 0 3px ${getColorBoxShadow({
        background,
        theme,
      })}`,
      content: '""',
      display: 'block',
      height: 'calc(100% + 1px)',
      position: 'absolute',
      width: 'calc(100% + 1px)',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  }

  return style;
});
