import type { BreadcrumbsFragment } from '@ori/editorial-fetching';
import type { Breadcrumb } from '@ori/presentation-components';

export const mapBreadcrumbsFromBreadcrumbLinks = (
  breadCrumbs?: BreadcrumbsFragment | null | undefined,
): Breadcrumb[] => {
  const links = breadCrumbs?.links ?? [];

  return links.map((link) => ({
    ItemLabel: link?.title ?? '',
    Url: link?.relativeUrl ?? '',
  }));
};
