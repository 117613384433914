import type { ProductListingRowFragment } from '@ori/editorial-fetching';
import { EditorialPlpPromoBannerSize } from '@ori/editorial-fetching';

export const getGridStyleFromData = (data?: ProductListingRowFragment | null | undefined) => {
  if (!data) {
    return '1fr 1fr 1fr 1fr';
  }

  const { content } = data;

  let columnLayout = '';

  content?.forEach((item) => {
    if (item) {
      if (item.__typename === 'Product') {
        columnLayout += '1fr ';
      } else {
        columnLayout += item.size === EditorialPlpPromoBannerSize.Size1 ? '1fr ' : '2fr ';
      }
    }
  });

  // In case last item(s) are not equal to 4 columns make it 4 columns to not stretch the grid
  const columnLayoutSum = columnLayout
    .trim()
    .split(' ')
    .reduce((acc, curr) => acc + Number.parseInt(curr, 10), 0);

  if (columnLayoutSum < 4) {
    const missingColumns = 4 - columnLayoutSum;
    columnLayout += `${1}fr `.repeat(missingColumns);
  }

  return columnLayout;
};
