import { Badge } from '@ori-ui/mui';
import type { PlpOrderBy } from '@ori/editorial-fetching';
import { useIsMobile } from '@ori/presentation-hooks';
import { useHeaders } from '@ori/presentation-http';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { useBannerProductListingContext } from '../../../../contexts';
import { useLoadDataAndUpdateState } from '../../../../contexts/utils';
import { useTranslations } from '../../../../hooks/useTranslations';
import { mapFacetFilterFromApi, mapOrderByFromApi } from '../../../../utils';
import type { FacetFilterValues } from '../../../FacetFilter';
import { FacetFilter } from '../../../FacetFilter';
import { FiltersContainer } from '../../../FiltersContainer';
import { Sorting } from '../../../Sorting';
import { ActionTypeEnum } from '../../types';
import type { BannerProductListingProps } from '../../types';
import { useFilter } from '../../utils/useFilter';
import { ACTIONS_ROOT } from './constants';
import { FilterContainerWrapper, FilterWrapper, Root } from './styles';

export const Actions: FC<BannerProductListingProps> = () => {
  const { getTestId } = useTestId();
  const { headers } = useHeaders();
  const { data, pageId, orderBy, appliedFacets, setAppliedFacets, sortLoading } = useBannerProductListingContext();
  const { loadDataAndUpdateState } = useLoadDataAndUpdateState();
  const isMobile = useIsMobile();
  const { translations } = useTranslations();
  const hasInitialFacets = (data?.facets?.length ?? 0) > 0;
  const mappedOrderByOptions = useMemo(() => mapOrderByFromApi(data?.orderByOptions), [data?.orderByOptions]);

  const loadDataFromFilter = useCallback(
    async (facetFiltering: FacetFilterValues[]) => {
      await loadDataAndUpdateState({
        orderByValue: orderBy,
        skipValue: 0,
        action: ActionTypeEnum.Filter,
        facetFiltering,
      });
    },
    [loadDataAndUpdateState, orderBy],
  );

  const onSort = useCallback(
    async (orderByValue: PlpOrderBy) => {
      await loadDataAndUpdateState({
        orderByValue,
        skipValue: 0,
        action: ActionTypeEnum.Sort,
        facetFiltering: appliedFacets,
      });
    },
    [loadDataAndUpdateState, appliedFacets],
  );
  const {
    filterValue,
    selectedFacets,
    onClear,
    onFacetFilterClear,
    onFilterApply,
    onFilterChange,
    onFilterClose,
    loading: facetsLoading,
  } = useFilter({
    initialValue: mapFacetFilterFromApi(data?.facets),
    headers,
    loadData: loadDataFromFilter,
    pageId,
    appliedFacets,
    setAppliedFacets,
  });

  return (
    <>
      <Root
        hasInitialFacets={hasInitialFacets}
        data-testid={getTestId(ACTIONS_ROOT)}
      >
        {hasInitialFacets ? (
          <FilterWrapper>
            <FacetFilter
              value={filterValue}
              title={translations.filter}
              showTitle={isMobile}
              isLoading={facetsLoading}
              settings={{
                clearButtonTitle: translations.clear,
                submitButtonTitle: translations.apply,
              }}
              onSubmit={onFilterApply}
              onChange={onFilterChange}
              onClose={onFilterClose}
            />
            {selectedFacets.length > 0 && isMobile ? (
              <Badge
                color="primary"
                badgeContent={selectedFacets.length}
                max={99}
              />
            ) : null}
          </FilterWrapper>
        ) : null}
        <Sorting
          options={mappedOrderByOptions}
          onChange={onSort}
        />
      </Root>
      {selectedFacets.length > 0 && !isMobile ? (
        <FilterContainerWrapper>
          <FiltersContainer
            isLoading={facetsLoading || sortLoading}
            selectedItems={selectedFacets}
            settings={{
              clearButtonTitle: translations.clearAllFilters,
            }}
            onClear={onClear}
            onDelete={onFacetFilterClear}
          />
        </FilterContainerWrapper>
      ) : null}
    </>
  );
};
