import type { Theme } from '@ori-ui/mui';

import { WHITE_COLORS } from '../constants';

interface GetColorBorder {
  background: string;
  theme: Theme;
}
export const getColorBorder = ({ background, theme }: GetColorBorder) =>
  WHITE_COLORS.includes(background) ? `1px solid ${theme.palette.grey[200]}` : undefined;
