import type { ProductListingRowsFragment, SearchResultsPageQuery } from '@ori/editorial-fetching';

export const mapSearchResultsPageQueryToProductListingRows = (
  data: SearchResultsPageQuery,
): ProductListingRowsFragment | undefined => {
  if (!data.application?.productSearchPage?.products) {
    return undefined;
  }
  const { products } = data.application.productSearchPage;

  return {
    __typename: 'EditorialPlpRows',
    facets: products.facets,
    orderByOptions: products.orderByOptions?.map((option) => ({
      __typename: 'PlpOrderingDefinition',
      ...option,
    })),
    totalProductsCount: products.totalProductsCount,
    rows: products.rows?.map((row) => ({
      __typename: 'EditorialPlpRow',
      content: row?.content?.map((content) => ({
        __typename: 'Product',
        productCode: content?.productCode ?? '',
        ...content,
      })),
    })),
  };
};
