import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(4),
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

export const ItemsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
}));

export const ButtonContainer = styled('div')(() => ({
  whiteSpace: 'nowrap',
}));
