import { ProductBoxSkeleton } from '@ori/product-box';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { PRODUCT_BOX_SKELETON } from '../../constants';
import { ContentRowsRoot } from './styles';
import type { ProductsLoadingProps } from './types';

export const ProductsLoading: FC<ProductsLoadingProps> = ({ items }) => {
  const { getTestId } = useTestId();

  return (
    <ContentRowsRoot gridRowStyle="1fr 1fr 1fr 1fr">
      {Array.from({ length: items }).map((_, index) => (
        <ProductBoxSkeleton
          /* eslint-disable-next-line react/no-array-index-key -- I don't have any other key */
          key={index}
          animation="pulse"
          data-testid={getTestId(PRODUCT_BOX_SKELETON)}
        />
      ))}
    </ContentRowsRoot>
  );
};
