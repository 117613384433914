import { styled } from '@ori-ui/mui';

export const Category = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  width: '100%',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(4),
}));
