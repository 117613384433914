import type { PlpOrderBy } from '@ori/editorial-fetching';

import type { FacetFilterValues } from '../FacetFilter';
import type { useGetProductListingData } from './utils/useGetProductListingData';

export interface BannerProductListingProps {
  className?: string | undefined;
  hasBottomDivider?: boolean | undefined;
}

export enum ActionTypeEnum {
  Filter = 'Filter',
  LoadMore = 'LoadMore',
  Sort = 'Sort',
}

export type ContentItems = Awaited<ReturnType<ReturnType<typeof useGetProductListingData>['getProductListingData']>>;

export interface LoadDataAndUpdateStateParams {
  action: ActionTypeEnum;
  skipValue: number;
  limit?: number;
  orderByValue: PlpOrderBy;
  facetFiltering: FacetFilterValues[];
}
