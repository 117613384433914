import { styled } from '@ori-ui/mui';
import { IntroText } from '@ori/presentation-components';

export const Inner = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8),
  },
}));

export const IntroTextStyled = styled(IntroText)(({ theme }) => ({
  margin: theme.spacing(0, 3),
  [theme.breakpoints.up('sm')]: {
    margin: 'auto',
  },
}));
