import { Link, useTheme } from '@ori-ui/mui';
import { EditorialPlpPromoBannerSize } from '@ori/graphql-schema';
import type { ImageSizesHelperParams } from '@ori/image-sizes-helper';
import { getImageVariants, generateSrcSet } from '@ori/image-sizes-helper';
import useTestId from '@ori/testid-generator';
import Head from 'next/head';
import { useMemo, type FC } from 'react';

import { BANNER_SIZE_IN_PX } from '../BannerProductListing/constants';
import {
  PROMO_BANNER,
  PROMO_BANNER_FAKE_LINK,
  PROMO_BANNER_IMAGE,
  PROMO_BANNER_TITLE,
  PROMO_BANNER_VIDEO,
} from './constants';
import { Banner, BannerImage, BannerTextContainer, BannerTitle, BannerTitleLinkImitation, BannerVideo } from './styles';
import type { PromoBannerProps } from './types';

/**
 * Promo banner component, used in product listing page
 */
export const PromoBanner: FC<PromoBannerProps> = ({
  backgroundImage,
  backgroundVideo,
  link,
  fontColor,
  verticalAlignment,
  title,
  size,
  className,
}) => {
  const { relativeUrl, title: linkTitle } = link ?? {};

  const theme = useTheme();
  const { getTestId } = useTestId();
  const imagesVariants = useMemo<ImageSizesHelperParams | undefined>(
    () =>
      backgroundImage
        ? {
            url: backgroundImage,
            backgroundColor: theme.palette.grey[100],
            imageFormat: 'WebP',
            params: [
              {
                width: BANNER_SIZE_IN_PX.Size1,
                quality: 90,
              },
              {
                width: BANNER_SIZE_IN_PX.Size2,
                quality: 90,
              },
              {
                width: 300,
                quality: 90,
              },
              {
                width: 300,
                quality: 90,
              },
              {
                width: 400,
                quality: 90,
              },
              {
                width: 600,
                quality: 90,
              },
            ],
          }
        : undefined,
    [backgroundImage, theme.palette.grey],
  );

  return (
    <Banner
      className={className}
      hasLink={!!relativeUrl}
      size={size}
      data-testid={getTestId(PROMO_BANNER)}
    >
      <Link href={relativeUrl ?? undefined}>
        {backgroundVideo?.id ? (
          <BannerVideo
            autoPlay
            playsInline
            muted
            data-testid={getTestId(PROMO_BANNER_VIDEO)}
            src={backgroundVideo.id}
            loop={backgroundVideo.playInLoop ?? true}
          />
        ) : (
          <>
            <BannerImage
              alt={linkTitle ?? ''}
              data-testid={getTestId(PROMO_BANNER_IMAGE)}
              fetchPriority="high"
              sizes={`(max-width: 450px) ${size === EditorialPlpPromoBannerSize.Size1 ? 200 : 400}px,
              (max-width: ${theme.breakpoints.values.sm - 1}px) ${size === EditorialPlpPromoBannerSize.Size1 ? 300 : 600}px, ${BANNER_SIZE_IN_PX[size]}px`}
              src={backgroundImage}
              srcSet={imagesVariants ? generateSrcSet(getImageVariants(imagesVariants)) : undefined}
            />
            <Head>
              <link
                rel="preload"
                as="image"
                href={backgroundImage}
                imageSrcSet={imagesVariants ? generateSrcSet(getImageVariants(imagesVariants)) : undefined}
                imageSizes={`(max-width: 450px) ${size === EditorialPlpPromoBannerSize.Size1 ? 200 : 400}px,
                (max-width: ${theme.breakpoints.values.sm - 1}px) ${size === EditorialPlpPromoBannerSize.Size1 ? 300 : 600}px, ${BANNER_SIZE_IN_PX[size]}px`}
              />
            </Head>
          </>
        )}

        <BannerTextContainer
          data-testid={getTestId(PROMO_BANNER_TITLE)}
          fontColor={fontColor}
          verticalAlignment={verticalAlignment}
        >
          <BannerTitle
            variant="h6"
            component="h6"
          >
            {title}
          </BannerTitle>
          {linkTitle ? (
            <BannerTitleLinkImitation
              variant="caption"
              fontColor={fontColor}
              data-testid={getTestId(PROMO_BANNER_FAKE_LINK)}
            >
              {linkTitle}
            </BannerTitleLinkImitation>
          ) : null}
        </BannerTextContainer>
      </Link>
    </Banner>
  );
};
