import { Placement } from '@ori/analytics-data';
import { getTypedQueryParam } from '@ori/presentation-utils';
import { useMemo } from 'react';

import { ORIGIN_QUERY_KEY } from '../components/BannerProductListing/constants';

const validatePlacement = (placement: Placement | null) => {
  if (placement === Placement.Typeform || placement === Placement.Revieve) {
    return placement;
  }

  return Placement.ProductListing;
};

export const useGetProductBoxPlacement = () => {
  // TODO -- use validate prop when it will be suitable for enums
  const placementParam = getTypedQueryParam<Placement>({
    name: ORIGIN_QUERY_KEY,
    fallback: Placement.ProductListing,
  });

  return useMemo(() => validatePlacement(placementParam), [placementParam]);
};
