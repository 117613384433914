import { PAGINATION_LIMIT_ROWS } from '../components/BannerProductListing/constants';
import type { LoadDataAndUpdateStateParams } from '../components/BannerProductListing/types';
import { ActionTypeEnum } from '../components/BannerProductListing/types';
import { mapFacetFilterValuesToFacetQuery } from '../utils';
import { useBannerProductListingContext } from './bannerProductListingContext';

export const useLoadDataAndUpdateState = () => {
  const { setContent, setPaginationLoading, setSkip, setSortLoading, setOrderBy, getData, pageId, setAppliedFacets } =
    useBannerProductListingContext();

  const loadDataAndUpdateState = async ({
    orderByValue,
    skipValue,
    limit = PAGINATION_LIMIT_ROWS,
    action,
    facetFiltering,
  }: LoadDataAndUpdateStateParams) => {
    setOrderBy(orderByValue);

    if (action === ActionTypeEnum.LoadMore) {
      setPaginationLoading(true);
    } else {
      setSortLoading(true);
      setSkip(limit); // Reset pagination on sort
    }

    const contentItems = await getData({
      facetFiltering: mapFacetFilterValuesToFacetQuery(facetFiltering),
      orderBy: orderByValue,
      pageId,
      skip: skipValue,
      limit,
    });

    contentItems?.forEach((item) => {
      if (item?.__typename === 'EditorialPlpRows') {
        if (action === ActionTypeEnum.LoadMore) {
          setContent((prev) => ({
            ...prev,
            rows: [...(prev?.rows ?? []), ...(item.rows ?? [])],
            totalBannersCount: item.totalBannersCount,
            totalProductsCount: item.totalProductsCount,
          }));
        } else {
          setContent((prev) => ({
            ...prev,
            rows: [...(item.rows ?? [])],
            totalBannersCount: item.totalBannersCount,
            totalProductsCount: item.totalProductsCount,
          }));
        }
      }
    });

    if (action === ActionTypeEnum.LoadMore) {
      setPaginationLoading(false);
      if (contentItems?.length ?? 0) {
        setSkip((prev) => prev + limit);
      }
    } else {
      setAppliedFacets(facetFiltering);
      setSortLoading(false);
    }
  };

  return { loadDataAndUpdateState };
};
