import type { FC } from 'react';

import { ColorOption } from './optionTypes/ColorOption';
import { DefaultOption } from './optionTypes/DefaultOption';
import type { FilterOptionProps } from './types';
import { isColorOption } from './utils/isColorOption';

export const FilterOption: FC<FilterOptionProps> = (props) => {
  const { label, selected, onClick, onDelete, LabelProps, OptionProps } = props;

  if (isColorOption(props)) {
    const { hexColors } = props;

    return (
      <ColorOption
        label={label}
        hexColors={hexColors}
        selected={selected}
        LabelProps={LabelProps}
        OptionProps={OptionProps}
        onClick={onClick}
        onDelete={onDelete}
      />
    );
  }

  return (
    <DefaultOption
      label={label}
      selected={selected}
      LabelProps={LabelProps}
      OptionProps={OptionProps}
      onClick={onClick}
      onDelete={onDelete}
    />
  );
};
