/* istanbul ignore file */
import { useToken } from '@ori/auth';
import { useServiceUrls } from '@ori/configuration-manager';
import { useEnvironment } from '@ori/environment';
import { ApiClientProvider as GlobalApiClientProvider } from '@ori/global-api-client-react';
import { useTranslationsWithNamespaces } from '@ori/i18n';
import { ApiClientProvider as StaticApiClientProvider } from '@ori/static-api-client-react';
import type { FC, PropsWithChildren } from 'react';

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const { language } = useTranslationsWithNamespaces();
  const { tenant } = useEnvironment();
  const { globalApiUrl, staticApiUrl } = useServiceUrls(['globalApi', 'staticApi']);
  const { formattedToken } = useToken();

  return (
    <GlobalApiClientProvider
      baseUrl={globalApiUrl}
      language={language}
      tenant={tenant}
      token={formattedToken}
    >
      <StaticApiClientProvider
        baseUrl={staticApiUrl}
        language={language}
        tenant={tenant}
      >
        {children}
      </StaticApiClientProvider>
    </GlobalApiClientProvider>
  );
};
