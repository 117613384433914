import { IconButton } from '@ori-ui/components';
import { Paper, Popper, ScopedCssBaseline, styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { FilterDropdownProps } from './types';

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: theme.spacing(1.4),
  margin: theme.spacing(2),
  background: 'rgba(255,255,255,.75)',
}));

export const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  userSelect: 'none',
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    paddingBottom: 0,
  },
}));

export const FilterDropdownRoot = styled(Paper, {
  shouldForwardProp: shouldForwardProp(),
})<Pick<FilterDropdownProps, 'showTitle'>>(({ theme, showTitle = true }) => ({
  display: 'flex',
  justifyItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(4),
  position: 'relative',
  width: 390,
  maxHeight: 625,
  outline: 0,
  padding: showTitle ? theme.spacing(6) : theme.spacing(8, 6, 6, 6),
  overflow: 'hidden',
  border: `1px solid ${theme.palette.grey[300]}`,
}));

export const Content = styled(ScopedCssBaseline)(() => ({
  overflowY: 'auto',
  maxHeight: '100%',
  background: 'transparent',
}));

export const ActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}));

export const Dropdown = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.fab,
}));
