import type { TypographyProps } from '@ori-ui/mui';

import type { FilterOptionProps } from '../FilterOption';

export const FILTERS_CONTAINER = 'filters-container';
export const FILTERS_CONTAINER_CLEAR_BUTTON = 'filters-container-clear-button';
export const COLOR_ICON_SIZE = 16;
export const OPTION_HEIGHT = 45;

export const optionProps: FilterOptionProps['OptionProps'] = {
  height: OPTION_HEIGHT,
  iconSize: COLOR_ICON_SIZE,
};
export const labelProps: TypographyProps = {
  fontWeight: 'bold',
};
