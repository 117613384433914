import { Filter } from '@ori-ui/icons';
import { Typography } from '@ori-ui/mui';
import { useDisclose } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import {
  type FC,
  type RefObject,
  useEffect,
  useState,
  createRef,
  useCallback,
  useMemo,
  useRef,
  type MouseEvent,
} from 'react';

import { FacetFilterCategory } from '../FacetFilterCategory/FacetFilterCategory';
import { FilterWindow } from '../FilterWindow';
import { Loading } from './components/Loading';
import { FACET_FILTER_BUTTON } from './constants';
import { Content, FilterButton } from './styles';
import type { FacetFilterValues, FacetFilterProps } from './types';

export const FacetFilter: FC<FacetFilterProps> = ({
  title,
  onSubmit,
  value: defaultValues,
  settings,
  dropdownPlacement,
  onChange,
  onClose: onCloseProp,
  showTitle = true,
  isLoading = false,
}) => {
  const { getTestId } = useTestId();
  const ref = useRef<HTMLDivElement>(null);
  const { onToggle, onClose: onCloseDropdown, open } = useDisclose();
  const [value, setValue] = useState<FacetFilterValues[] | undefined>(defaultValues);
  const [openedCategory, setOpenedCategory] = useState<string | null>(null);
  const categoryRefs = useMemo(
    () =>
      value?.reduce<Record<string, RefObject<HTMLDivElement>>>(
        (acc, category) => ({
          ...acc,
          [category.key]: createRef<HTMLDivElement>(),
        }),
        {},
      ),
    [value],
  );

  useEffect(() => {
    setValue(defaultValues);
  }, [defaultValues]);

  const onFilterClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onToggle();
    },
    [onToggle],
  );

  const close = useCallback(() => {
    onCloseDropdown();
    setOpenedCategory(null);
  }, [onCloseDropdown]);

  const onClose = useCallback(() => {
    close();
    onCloseProp?.();
  }, [close, onCloseProp]);

  const onOptionClick = useCallback(
    (category: string, optionValue: string) => {
      const updatedValue = value?.map((faceFilterValue) => {
        if (faceFilterValue.key === category) {
          const updatedItems = faceFilterValue.items.map((item) =>
            item.value === optionValue ? { ...item, selected: !item.selected } : item,
          );

          return { ...faceFilterValue, items: updatedItems };
        }

        return faceFilterValue;
      });
      onChange?.(updatedValue ?? []);
    },
    [onChange, value],
  );
  const onClear = useCallback(() => {
    const clearedValue = value?.map((faceFilterValue) => ({
      ...faceFilterValue,
      items: faceFilterValue.items.map((item) => ({ ...item, selected: false })),
    }));
    onChange?.(clearedValue ?? []);
    close();
    onSubmit(clearedValue ?? []);
  }, [onChange, close, value, onSubmit]);

  const onCategoryClick = useCallback(
    (category: string) => {
      if (openedCategory === category) {
        setOpenedCategory(null);
      } else {
        setOpenedCategory(category);
        // TODO -- make this work on scrollable page without scrolling the whole page
        // categoryRefs?.[category]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    [openedCategory],
  );

  const onSubmitInternal = useCallback(() => {
    onSubmit(value ?? []);
    close();
  }, [onSubmit, value, close]);

  return (
    <>
      <FilterButton
        ref={ref}
        data-testid={getTestId(FACET_FILTER_BUTTON)}
        onClick={onFilterClick}
      >
        <Filter />
        <Typography
          fontWeight="bold"
          color="textPrimary"
          textTransform="uppercase"
        >
          {title}
        </Typography>
      </FilterButton>
      <FilterWindow
        settings={settings}
        anchorEl={ref.current}
        isOpen={open}
        showTitle={showTitle}
        placement={dropdownPlacement}
        title={title}
        onClose={onClose}
        onClear={onClear}
        onSubmit={onSubmitInternal}
      >
        <Content>
          <Loading isLoading={isLoading} />
          {value?.map((category, index) => (
            <FacetFilterCategory
              key={category.key}
              category={category}
              openedCategory={openedCategory}
              categoryRef={categoryRefs?.[category.key]}
              isFirst={index === 0}
              onCategoryClick={onCategoryClick}
              onOptionClick={onOptionClick}
            />
          ))}
        </Content>
      </FilterWindow>
    </>
  );
};
