import { styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

interface RootProps {
  hasInitialFacets?: boolean;
}
export const Root = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<RootProps>(({ hasInitialFacets = true }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: hasInitialFacets ? 'space-between' : 'flex-end',
  alignItems: 'center',
  '&:empty': {
    display: 'none',
  },
}));

export const FilterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

export const FilterContainerWrapper = styled('div')(() => ({
  width: '100%',
}));
