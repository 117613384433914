import { Typography, useTheme } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { BASE_OPTION, BASE_OPTION_LABEL, BASE_OPTION_SELECTED } from '../constants';
import { StyledBaseChip } from '../styles';
import type { BaseOptionProps } from './types';

export const BaseOption: FC<BaseOptionProps> = ({
  LabelProps: labelProps,
  onClick,
  color,
  label,
  icon,
  clickable,
  variant,
  sx,
  height,
  selected,
  onDelete,
}) => {
  const { palette } = useTheme();
  const { getTestId } = useTestId();

  const customLabel =
    typeof label === 'string' ? (
      <Typography
        data-testid={getTestId(BASE_OPTION_LABEL)}
        component="span"
        variant="body2"
        fontWeight="regular"
        textTransform="capitalize"
        color={selected ? palette.success.contrastText : palette.text.primary}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ...labelProps?.sx,
        }}
        {...labelProps}
      >
        {label}
      </Typography>
    ) : (
      label
    );

  return (
    <StyledBaseChip
      data-testid={getTestId(selected ? BASE_OPTION_SELECTED : BASE_OPTION)}
      selected={selected}
      color={color}
      label={customLabel}
      icon={icon}
      height={height}
      clickable={clickable}
      variant={variant}
      sx={sx}
      onDelete={onDelete}
      onClick={onClick}
    />
  );
};
