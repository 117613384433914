import { Placement } from '@ori/analytics-data';
import { useToken } from '@ori/auth';
import { useAreFavoritesEnabled } from '@ori/presentation-hooks';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { useGetProductBoxPlacement } from '../utils/useGetProductBoxPlacement';

export interface ProductListingContext {
  hideRating: boolean;
  favoritesEnabled: boolean;
  placement: Placement;
}

const defaultProductListingContextValues: ProductListingContext = {
  hideRating: false,
  favoritesEnabled: false,
  placement: Placement.ProductListing,
};

export const productListingContext = createContext<ProductListingContext>(defaultProductListingContextValues);

const { Provider } = productListingContext;

export interface ProductListingStoreProviderProps extends PropsWithChildren {
  hideRating: boolean;
}

export const ProductListingProvider: FC<ProductListingStoreProviderProps> = ({ hideRating, children }) => {
  const { customerId } = useToken();
  const favoritesEnabled = useAreFavoritesEnabled({ customerId });
  const placement = useGetProductBoxPlacement();

  const value = useMemo(() => ({ hideRating, favoritesEnabled, placement }), [hideRating, favoritesEnabled, placement]);

  return <Provider value={value}>{children}</Provider>;
};

export const useProductListingContext = () => useContext(productListingContext);
