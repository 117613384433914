/* istanbul ignore file */
import { useServiceUrls } from '@ori/configuration-manager';
import type { EditorialPlpRowsFacetFilter, PlpOrderBy } from '@ori/editorial-fetching';
import { useHeaders } from '@ori/presentation-http';
import { useCallback } from 'react';

import { PAGINATION_LIMIT_ROWS } from '../constants';
import { loadProductListingData } from './loadProductListingData';

interface UseGetProductListingDataParams {
  pageId: string;
}

export interface GetProductListingDataParams {
  skip: number;
  limit?: number;
  pageId: string;
  orderBy: PlpOrderBy;
  facetFiltering?: EditorialPlpRowsFacetFilter[];
}
export const useGetProductListingData = ({ pageId }: UseGetProductListingDataParams) => {
  const { graphQlUrl } = useServiceUrls(['graphQl']);
  const { headers } = useHeaders();

  const getProductListingData = useCallback(
    async ({ skip, limit = PAGINATION_LIMIT_ROWS, orderBy, facetFiltering }: GetProductListingDataParams) => {
      const response = await loadProductListingData({
        limit,
        skip,
        pageId,
        orderBy,
        graphQlUrl,
        headers,
        facetFiltering,
      });

      return response?.application?.editorialPage?.contentItems;
    },
    [graphQlUrl, headers, pageId],
  );

  return { getProductListingData };
};
