import { editorialCarouselMock } from '@ori/editorial-components';
import {
  BackgroundType,
  ColorType,
  EditorialBannerLayoutEnum,
  EditorialBannerTitleColorEnum,
  EditorialBannerTitleSizeEnum,
  OlapicTypeEnum,
  PlpOrderBy,
  ProductLabelDomainType,
  type ProductListingPageQuery,
  StorybookColor,
  VideoType,
} from '@ori/editorial-fetching';

export const productListingQuery: ProductListingPageQuery = {
  application: {
    breadcrumbLinks: {
      links: [
        {
          relativeUrl: '/',
          title: 'Hlavní strana',
        },
        {
          relativeUrl: '/skincare',
          title: 'Péče o pleť',
        },
      ],
    },
    pageNavigation: {
      __typename: 'PageNavigation',
      pageId: 'skincare/moisturisers/night-cream',
      items: [
        {
          __typename: 'PageNavigationItem',
          itemId: '7ee5469c-aeac-4281-b767-43f23ca87603',
          label: 'Hydratace',
          url: '/skincare/moisturisers',
        },
        {
          __typename: 'PageNavigationItem',
          itemId: '1cd0d0db-ce64-4dff-a76e-b8c84876f50b',
          label: 'Denní krémy',
          url: '/skincare/moisturisers/day-cream',
        },
        {
          __typename: 'PageNavigationItem',
          itemId: '98119c1a-b0f3-4412-838f-7eedad307091',
          label: 'Noční krémy',
          url: '/skincare/moisturisers/night-cream',
        },
        {
          __typename: 'PageNavigationItem',
          itemId: '609a9fb3-d036-4323-a721-671f7eb294a2',
          label: 'BB a CC krémy',
          url: '/skincare/moisturisers/bb-cc-cream',
        },
      ],
    },
    editorialPage: {
      pageId: '/skincare/moisturisers/night-cream',
      title: 'Noční krémy',
      thumbnailUrl: null,
      contentItems: [
        {
          __typename: 'EditorialHeader',
          id: '98119c1a-b0f3-4412-838f-7eedad307091',
          headline: 'Noční krémy',
          headlineStorybookColor: StorybookColor.DefaultContrastText,
          backgroundStorybookColor: StorybookColor.Blue100,
          imageUrl: null,
          text: 'Vyberte si ten nejlepší noční krém z naší řady speciálně vytvořených nočních krémů, které pleť vyživují a regenerují, zatímco spíte. Můžete volit mezi nočními krémy pro všechny typy pleti včetně smíšené pleti, mastné pleti či suché pleti, ale i mezi nočními krémy proti stárnutí, které cílí na vrásky a jemné linky.',
        },
        {
          __typename: 'EditorialPlpRows',
          totalBannersCount: 0,
          totalProductsCount: 15,
          orderByOptions: [
            {
              __typename: 'PlpOrderingDefinition',
              displayText: 'Podle doporučení',
              orderBy: PlpOrderBy.Recommended,
            },
            {
              __typename: 'PlpOrderingDefinition',
              displayText: 'Nejnovější',
              orderBy: PlpOrderBy.Latest,
            },
            {
              __typename: 'PlpOrderingDefinition',
              displayText: 'Nejvyšší hodnocení',
              orderBy: PlpOrderBy.HighestRating,
            },
            {
              __typename: 'PlpOrderingDefinition',
              displayText: 'Nejnižší cena',
              orderBy: PlpOrderBy.LowestPrice,
            },
            {
              __typename: 'PlpOrderingDefinition',
              displayText: 'Nejvyšší cena',
              orderBy: PlpOrderBy.HighestPrice,
            },
            {
              __typename: 'PlpOrderingDefinition',
              displayText: 'Největší sleva',
              orderBy: PlpOrderBy.HighestDiscount,
            },
          ],
          facets: [
            {
              name: 'brand',
              active: null,
              displayName: 'brand',
              values: [
                {
                  key: 'optimals',
                  count: 5,
                  active: null,
                  displayName: 'optimals',
                },
                {
                  key: 'novage',
                  count: 2,
                  active: null,
                  displayName: 'novage',
                },
                {
                  key: 'novage-plus',
                  count: 2,
                  active: null,
                  displayName: 'novage-plus',
                },
                {
                  key: 'diamond-cellular',
                  count: 1,
                  active: null,
                  displayName: 'diamond-cellular',
                },
                {
                  key: 'love-nature',
                  count: 1,
                  active: null,
                  displayName: 'love-nature',
                },
                {
                  key: 'pure-skin',
                  count: 1,
                  active: null,
                  displayName: 'pure-skin',
                },
                {
                  key: 'royal-velvet',
                  count: 1,
                  active: null,
                  displayName: 'royal-velvet',
                },
                {
                  key: 'waunt',
                  count: 1,
                  active: null,
                  displayName: 'waunt',
                },
              ],
            },
          ],
          rows: [
            {
              __typename: 'EditorialPlpRow',
              content: [
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Optimals',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '42553',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Noční krém Optimals Even Out',
                    ratingAverage: 4.6512,
                    ratingCount: 410,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '42553',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '460,00 Kč',
                      currentPrice: '199,90 Kč',
                      previousMinOfferPrice: '229,90 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 199.9,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f42553%2f42553_1.png&id=14861236&version=2',
                  productCode: '42553',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Diamond Cellular',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '18437',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Noční péče pro omlazení pleti Diamond Cellular',
                    ratingAverage: 4.7488,
                    ratingCount: 2803,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '18437',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '900,00 Kč',
                      currentPrice: '439,00 Kč',
                      previousMinOfferPrice: '439,00 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 439,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f18437%2fCZ%2f18437_1.png&id=14989293&version=6',
                  productCode: '18437',
                  replacedByProduct: {
                    productCode: '32544',
                  },
                  replacementForProduct: null,
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Optimals',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '42549',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Omlazující noční krém Optimals Age Revive',
                    ratingAverage: 4.6418,
                    ratingCount: 469,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '42549',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '460,00 Kč',
                      currentPrice: '229,90 Kč',
                      previousMinOfferPrice: '219,90 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 229.9,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f42549%2f42549_1.png&id=2024-03-06T09-54-07-648Z_MediaMigration&version=1674547201',
                  productCode: '42549',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Novage+',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '38385',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Noční krém pro citlivou pleť NovAge Skinrelief',
                    ratingAverage: 4.6552,
                    ratingCount: 116,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '38385',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '730,00 Kč',
                      currentPrice: '730,00 Kč',
                      previousMinOfferPrice: '730,00 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 730,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f38385%2f38385_1.png&id=15203538&version=3',
                  productCode: '38385',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
              ],
            },
            {
              __typename: 'EditorialPlpRow',
              content: [
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Optimals',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '42589',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Intenzivně vyživující noční krém Optimals Hydra Radiance',
                    ratingAverage: 4.5028,
                    ratingCount: 358,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '42589',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '400,00 Kč',
                      currentPrice: '189,90 Kč',
                      previousMinOfferPrice: '199,90 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 189.9,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f42589%2f42589_1.png&id=14944540&version=2',
                  productCode: '42589',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Novage+',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '41057',
                    labels: [
                      {
                        labelKey: 'Offer',
                        productLabelDomain: ProductLabelDomainType.Deal,
                        title: 'Nabídka',
                      },
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Lehký omlazující noční krém Multi-Active Novage+',
                    ratingAverage: 4.6577,
                    ratingCount: 111,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '41057',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '900,00 Kč',
                      currentPrice: '900,00 Kč',
                      previousMinOfferPrice: '900,00 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'Offer',
                      productLabelDomain: ProductLabelDomainType.Deal,
                      title: 'Nabídka',
                    },
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 900,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2f41057%2f41057.png%3fversion%3d1675683039',
                  productCode: '41057',
                  replacedByProduct: null,
                  replacementForProduct: {
                    productCode: '33982',
                  },
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Novage+',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '41058',
                    labels: [
                      {
                        labelKey: 'Offer',
                        productLabelDomain: ProductLabelDomainType.Deal,
                        title: 'Nabídka',
                      },
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Hutný omlazující noční krém Multi-Active Novage+',
                    ratingAverage: 4.7879,
                    ratingCount: 198,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '41058',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '900,00 Kč',
                      currentPrice: '900,00 Kč',
                      previousMinOfferPrice: '900,00 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'Offer',
                      productLabelDomain: ProductLabelDomainType.Deal,
                      title: 'Nabídka',
                    },
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 900,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f41058%2f41058_1.png&id=17447607&version=1',
                  productCode: '41058',
                  replacedByProduct: null,
                  replacementForProduct: {
                    productCode: '34549',
                  },
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Love Nature',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '34845',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Zmatňující pleťové mléko s bio čajovníkem a limetkou Love Nature',
                    ratingAverage: 4.6362,
                    ratingCount: 470,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '34845',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '200,00 Kč',
                      currentPrice: '119,90 Kč',
                      previousMinOfferPrice: '99,90 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 119.9,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f34845%2fCZ%2f34845_1.png&id=2024-03-06T09-17-33-885Z_MediaMigration&version=1643798703',
                  productCode: '34845',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
              ],
            },
            {
              __typename: 'EditorialPlpRow',
              content: [
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Optimals',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '44260',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Noční krém Optimals Urban Guard 3D',
                    ratingAverage: 4.6013,
                    ratingCount: 153,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '44260',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '490,00 Kč',
                      currentPrice: '490,00 Kč',
                      previousMinOfferPrice: '',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 490,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f44260%2f44260_1.png&id=14819095&version=2',
                  productCode: '44260',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Novage+',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '32628',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Noční regenerační krém NovAge Time Restore',
                    ratingAverage: 4.7185,
                    ratingCount: 920,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '32628',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '880,00 Kč',
                      currentPrice: '880,00 Kč',
                      previousMinOfferPrice: '880,00 Kč',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 880,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f32628%2f32628_1.png&id=10316285&version=6',
                  productCode: '32628',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Royal Velvet',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '22814',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Obnovující noční krém Royal Velvet',
                    ratingAverage: 4.7457,
                    ratingCount: 2709,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '22814',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '580,00 Kč',
                      currentPrice: '580,00 Kč',
                      previousMinOfferPrice: '',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 580,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f22814%2fCZ%2f22814_1.png&id=14989294&version=4',
                  productCode: '22814',
                  replacedByProduct: null,
                  replacementForProduct: {
                    productCode: '4643',
                  },
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Optimals',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '42587',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Noční krém Optimals Hydra Radiance',
                    ratingAverage: 4.5528,
                    ratingCount: 322,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '42587',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '400,00 Kč',
                      currentPrice: '400,00 Kč',
                      previousMinOfferPrice: '',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 400,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f42587%2f42587_1.png&id=2024-03-06T09-54-19-329Z_MediaMigration&version=1674547220',
                  productCode: '42587',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
              ],
            },
            {
              __typename: 'EditorialPlpRow',
              content: [
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Waunt',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '41372',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Noční pleťová maska Waunt Revive Me',
                    ratingAverage: 4.668,
                    ratingCount: 250,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '41372',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '610,00 Kč',
                      currentPrice: '610,00 Kč',
                      previousMinOfferPrice: '',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 610,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f41372%2fCZ%2f41372_1.png&id=2024-03-06T09-39-08-653Z_MediaMigration&version=1700496001',
                  productCode: '41372',
                  replacedByProduct: null,
                  replacementForProduct: {
                    productCode: '35075',
                  },
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: {
                      name: 'Pure Skin',
                    },
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '41673',
                    labels: [
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Zmatňující pleťové mléko Pure Skin',
                    ratingAverage: 4.6682,
                    ratingCount: 217,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '41673',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '230,00 Kč',
                      currentPrice: '230,00 Kč',
                      previousMinOfferPrice: '',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 230,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f41673%2f41673_1.png&id=15037602&version=3',
                  productCode: '41673',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
                {
                  __typename: 'Product',
                  backInStockAvailability: {
                    showBackInStockNotification: false,
                  },
                  concept: {
                    brand: null,
                    categories: [
                      {
                        name: 'Péče o pleť',
                      },
                    ],
                    conceptCode: '35149',
                    labels: [
                      {
                        labelKey: 'LastChance',
                        productLabelDomain: ProductLabelDomainType.Ppa,
                        title: 'Poslední možnost',
                      },
                      {
                        labelKey: 'AddToBasketVisible',
                        productLabelDomain: ProductLabelDomainType.Shopping,
                        title: null,
                      },
                    ],
                    name: 'Univerzální krém s výtažky z lesních borůvek',
                    ratingAverage: 4.6667,
                    ratingCount: 60,
                    products: [
                      {
                        hexColors: [],
                        colorImageUrl: '',
                        productCode: '35149',
                      },
                    ],
                  },
                  formattedPrice: {
                    price: {
                      basicCataloguePrice: '190,00 Kč',
                      currentPrice: '190,00 Kč',
                      previousMinOfferPrice: '',
                    },
                  },
                  labels: [
                    {
                      labelKey: 'LastChance',
                      productLabelDomain: ProductLabelDomainType.Ppa,
                      title: 'Poslední možnost',
                    },
                    {
                      labelKey: 'AddToBasketVisible',
                      productLabelDomain: ProductLabelDomainType.Shopping,
                      title: null,
                    },
                  ],
                  price: {
                    price: {
                      currentPrice: 190,
                    },
                  },
                  imageUrl:
                    'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f35149%2f35149_1.png&id=2024-03-06T09-17-51-461Z_MediaMigration&version=1594222394',
                  productCode: '35149',
                  replacedByProduct: null,
                  replacementForProduct: null,
                },
              ],
            },
          ],
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.Headline,
          modules: [
            {
              content: {
                title: 'Hi Awesome!',
                textCollection: null,
              },
              backgroundType: BackgroundType.None,
              backgroundVideo: null,
              backgroundImage: null,
              backgroundColor: {
                type: ColorType.White,
              },
            },
          ],
          id: '38.70376441529448',
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.FourMedia,
          modules: [
            {
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/productImage/?externalMediaId=product-management-media%2F40797%2FUK%2F40797_4.png%3Fversion%3D1658307615',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              link: null,
              content: {
                title:
                  'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Curabitur ligula sapien, pulvinar a vestibulum quis',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/productImage/?externalMediaId=product-management-media%2F41338%2FUK%2F41338_3.png%3Fversion%3D1660043733',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              link: {
                data: {
                  __typename: 'Product',
                  productCode: '43512',
                  price: {
                    price: {
                      currentPrice: 1,
                    },
                  },
                },
              },
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/productImage/?externalMediaId=product-management-media%2F41338%2FUK%2F41338_3.png%3Fversion%3D1660043733',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              link: {
                data: {
                  __typename: 'Product',
                  productCode: '43512',
                  price: {
                    price: {
                      currentPrice: 1,
                    },
                  },
                },
              },
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/productImage/?externalMediaId=product-management-media%2F40797%2FUK%2F40797_4.png%3Fversion%3D1658307615',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
          ],
          id: '35.24289288385514',
        },
        {
          __typename: 'EditorialBanner',
          id: 'adb0ef6d-6d3a-45a7-960f-7748005ad6b8',
          layout: EditorialBannerLayoutEnum.Quote,
          modules: [
            {
              backgroundColor: {
                type: ColorType.Green,
              },
              content: {
                title: '"Excellent cleansing gel for my oily skin, I use it every morning and night with my ritual"',
                titleColor: EditorialBannerTitleColorEnum.White,
                titleSize: EditorialBannerTitleSizeEnum.H5,
                textCollection: [
                  {
                    text: '',
                  },
                ],
              },
              backgroundType: BackgroundType.Color,
              backgroundVideo: null,
              link: null,
              backgroundImage: null,
            },
          ],
        },
        {
          __typename: 'EditorialBanner',
          id: '71c8761d-ad4d-476b-9de0-770b311d092e',
          layout: EditorialBannerLayoutEnum.Video,
          modules: [
            {
              backgroundColor: null,
              content: null,
              backgroundType: BackgroundType.Video,
              backgroundVideo: {
                autoplayOnScroll: false,
                id: 'https://media-uat-ce-cdn.oriflame.com/contentMedia/?externalMediaId=cec5dc8c-f55d-4756-a9b2-219fefba77dd&mimeType=video%2fmp4',
                playInLoop: false,
                type: VideoType.Mp4,
              },
              link: null,
              backgroundImage: null,
            },
          ],
        },
        {
          __typename: 'EditorialBanner',
          id: '444b651d-6806-475e-b477-c4727bc70e8d',
          layout: EditorialBannerLayoutEnum.OneMediaOverlayRightText,
          modules: [
            {
              backgroundColor: null,
              content: null,
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              link: null,
              backgroundImage: {
                url: 'https://media-uat-ce-cdn.oriflame.com/contentImage?externalMediaId=6f790b87-7269-4349-86ba-0f0581e1571f&name=OneMediaCenterBottom+-+picture&inputFormat=png',
              },
            },
            {
              backgroundColor: null,
              content: {
                title: 'Title: Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
                titleColor: EditorialBannerTitleColorEnum.Black,
                titleSize: EditorialBannerTitleSizeEnum.H5,
                showReadMoreOption: false,
                textCollection: [
                  {
                    text: '',
                  },
                ],
              },
              backgroundType: BackgroundType.None,
              backgroundVideo: null,
              link: {
                relativeUrl: '',
                title: 'https://cz-uat.oriflame.com/skincare/mask/sheet-mask',
                data: null,
              },
              backgroundImage: null,
            },
          ],
        },
        {
          __typename: 'EditorialBanner',
          id: 'b9ed829a-4110-4418-bb04-0363e17a006e',
          layout: EditorialBannerLayoutEnum.Video,
          modules: [
            {
              backgroundColor: null,
              content: null,
              backgroundType: BackgroundType.Video,
              backgroundVideo: {
                autoplayOnScroll: false,
                id: 'NJS6NCccSR4',
                playInLoop: false,
                type: VideoType.Youtube,
              },
              link: null,
              backgroundImage: null,
            },
          ],
        },
        {
          __typename: 'EditorialCarousel',
          id: '71c8761d-ad4d-476b-9de0-770b311ddsds1',
          editorials: editorialCarouselMock,
        },
        {
          __typename: 'EditorialProductBoxCarousel',
          headline: 'Doporučené produkty',
          products: [
            {
              __typename: 'Product',
              productCode: '123',
              formattedPrice: {
                __typename: 'ProductFormattedPrice',
                price: { __typename: 'FormattedPrice', currentPrice: '230,00 Kč' },
              },
              imageUrl:
                'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f41673%2f41673_1.png&id=15037602&version=3',
              concept: {
                __typename: 'Concept',
                name: 'Zmatňující pleťové mléko Pure Skin',
                conceptCode: '123',
                ratingAverage: 4.6682,
                ratingCount: 217,
                brand: {
                  __typename: 'Brand',
                  name: 'Pure Skin',
                },
              },
            },
            {
              __typename: 'Product',
              productCode: '234',
              imageUrl:
                'https://media-uat-cdn.oriflame.com/productImage?externalMediaId=product-management-media%2fProducts%2f32628%2f32628_1.png&id=10316285&version=6',
              formattedPrice: {
                __typename: 'ProductFormattedPrice',
                price: { __typename: 'FormattedPrice', currentPrice: '880,00 Kč' },
              },
              concept: {
                ratingAverage: 4.7185,
                ratingCount: 920,
                __typename: 'Concept',
                name: 'Noční regenerační krém NovAge Time Restore',
                conceptCode: '234',
                brand: {
                  __typename: 'Brand',
                  name: 'Novage+',
                },
              },
            },
          ],
        },
        {
          __typename: 'EditorialOlapic',
          product: {
            productCode: '12760',
          },
          type: OlapicTypeEnum.ProductDetails,
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.TwoMedia,
          modules: [
            {
              content: {
                title:
                  'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Curabitur ligula sapien, pulvinar a vestibulum quis',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=7fa50644-ea40-4c64-8bc1-3596ad3c5363&name=3-1_46439_492x650&inputFormat=jpg',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=b44760d7-4491-4fff-9684-eb8882626241&name=3-2_46439_492x650&inputFormat=jpg',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
          ],
          id: '15.255730812605675',
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.OneMediaOverlayLeftText,
          modules: [
            {
              link: null,
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/contentImage?u=1909111320&externalMediaId=a45e22fb-8352-4124-939a-88a6b6985494&name=img-2&',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              content: {
                title:
                  'LOREM IPSUM DOLOR SIT AMET, SEA TE MUNERE PERTINAX TORQUATOS LOREM IPSUM DOLOR SIT AMET TORQUATOS COPED.',
                textCollection: null,
              },
              backgroundType: BackgroundType.None,
              backgroundVideo: null,
              backgroundImage: null,
              backgroundColor: {
                type: ColorType.White,
              },
            },
          ],
          id: '12.590437169432267',
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.ThreeMediaRightBottomText,
          modules: [
            {
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/contentImage?externalMediaId=d4df775e-153e-4711-93f8-a8c7823d259c&u=0101010000',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              link: {
                data: {
                  __typename: 'Product',
                  productCode: '43512',
                  price: {
                    price: {
                      currentPrice: 1,
                    },
                  },
                },
              },
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/productImage/?externalMediaId=product-management-media%2F41338%2FUK%2F41338_3.png%3Fversion%3D1660043733',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              link: {
                data: {
                  __typename: 'Product',
                  productCode: '43512',
                  price: {
                    price: {
                      currentPrice: 1,
                    },
                  },
                },
              },
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/productImage/?externalMediaId=product-management-media%2F40797%2FUK%2F40797_4.png%3Fversion%3D1658307615',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              content: {
                title:
                  'LOREM IPSUM DOLOR SIT AMET, SEA TE MUNERE PERTINAX TORQUATOS LOREM IPSUM DOLOR SIT AMET TORQUATOS COPED.',
                textCollection: [
                  {
                    text: 'Lorem ipsum dolor sit amet, sea te munere pertinax torquatos. Cu sea odio sonet explicari, at duo dicant dolores. Tota falli fabulas ne mea, facete audire suscipiantur id nec, tincidunt definitiones ut his. Suas mustang bobek',
                  },
                ],
              },
              backgroundType: BackgroundType.None,
              backgroundVideo: null,
              backgroundImage: null,
              backgroundColor: {
                type: ColorType.White,
              },
            },
          ],
          id: '3.006379639405077',
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.OneMediaCenterBottomText,
          modules: [
            {
              content: {
                title:
                  'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Curabitur ligula sapien, pulvinar a vestibulum quis',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-we-cdn.oriflame.com/productImage/?externalMediaId=product-management-media%2F41338%2FUK%2F41338_3.png%3Fversion%3D1660043733',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
          ],
          id: '93.96198571204617',
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.OneMediaParagraphText,
          modules: [
            {
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=7fa50644-ea40-4c64-8bc1-3596ad3c5363&name=3-1_46439_492x650&inputFormat=jpg',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              link: null,
              content: {
                showReadMoreOption: true,
                title:
                  'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Curabitur ligula sapien, pulvinar a vestibulum quis',
                textCollection: [
                  {
                    text: '<h4>Lorem ipsum dolor sit</h4>',
                  },
                  {
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mattis vulputate enim nulla aliquet porttitor. Massa tincidunt dui ut ornare. Amet massa vitae tortor condimentum lacinia. Aliquam etiam erat velit scelerisque in. Pellentesque dignissim enim sit amet venenatis urna cursus eget nunc. Ultrices eros in cursus turpis massa tincidunt dui ut ornare. Penatibus et magnis dis parturient montes. Blandit aliquam etiam erat velit scelerisque in dictum non. Facilisis sed odio morbi quis commodo odio aenean sed adipiscing. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Molestie nunc non blandit massa enim nec. In nibh mauris cursus mattis molestie a iaculis at erat. Morbi tincidunt ornare massa eget. Tincidunt ornare massa eget egestas purus.',
                  },
                  {
                    text: 'Morbi <b>tincidunt ornare</b> massa eget egestas purusius quam quisque id diam. At varius vel pharetra vel turpis nunc eget lorem. Habitasse platea dictumst vestibulum rhoncus. Tempor orci dapibus ultrices in iaculis. Duis at consectetur lorem donec massa sapien faucibus et molestie. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eu augue ut lectus arcu bibendum at. Ac ut consequat semper viverra nam libero justo.',
                  },
                  {
                    text: 'Morbi tinciduus viverra accumsan. Sed arcu non odio euismod lacinia. Risus quis varius quam quisque id diam. At varius vel pharetra vel turpis nunc eget lorem. Habitasse platea dictumst vestibulum rhoncus. Tempor orci dapibus ultrices in iaculis. Duis at consectetur lorem donec massa sapien faucibus et molestie. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eu augue ut lectus arcu bibendum at. Ac ut consequat semper viverra nam libero justo.',
                  },
                  {
                    text: 'Morbi tinciduus viverra accumsan. Sed arcu non odio euismod lacinia. Risus quis varius quam quisque id diam. At varius vel pharetra vel turpis nunc eget lorem. Habitasse platea dictumst vestibulum rhoncus. Tempor orci dapibus ultrices in iaculis. Duis at consectetur lorem donec massa sapien faucibus et molestie. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eu augue ut lectus arcu bibendum at. Ac ut consequat semper viverra nam libero justo.',
                  },
                  {
                    text: 'Morbi tinciduus viverra accumsan. Sed arcu non odio euismod lacinia. Risus quis varius quam quisque id diam. At varius vel pharetra vel turpis nunc eget lorem. Habitasse platea dictumst vestibulum rhoncus. Tempor orci dapibus ultrices in iaculis. Duis at consectetur lorem donec massa sapien faucibus et molestie. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eu augue ut lectus arcu bibendum at. Ac ut consequat semper viverra nam libero justo.',
                  },
                ],
              },
              backgroundType: BackgroundType.None,
              backgroundVideo: null,
              backgroundImage: null,
              backgroundColor: {
                type: ColorType.White,
              },
            },
          ],
          id: '66.28676865960857',
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.ParagraphText,
          modules: [
            {
              link: null,
              content: {
                showReadMoreOption: true,
                title:
                  'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Curabitur ligula sapien, pulvinar a vestibulum quis',
                textCollection: [
                  {
                    text: '<h4>Lorem ipsum dolor sit</h4>',
                  },
                  {
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Mattis vulputate enim nulla aliquet porttitor. Massa tincidunt dui ut ornare. Amet massa vitae tortor condimentum lacinia. Aliquam etiam erat velit scelerisque in. Pellentesque dignissim enim sit amet venenatis urna cursus eget nunc. Ultrices eros in cursus turpis massa tincidunt dui ut ornare. Penatibus et magnis dis parturient montes. Blandit aliquam etiam erat velit scelerisque in dictum non. Facilisis sed odio morbi quis commodo odio aenean sed adipiscing. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Molestie nunc non blandit massa enim nec. In nibh mauris cursus mattis molestie a iaculis at erat. Morbi tincidunt ornare massa eget. Tincidunt ornare massa eget egestas purus.',
                  },
                  {
                    text: 'Morbi <b>tincidunt ornare</b> massa eget egestas purusius quam quisque id diam. At varius vel pharetra vel turpis nunc eget lorem. Habitasse platea dictumst vestibulum rhoncus. Tempor orci dapibus ultrices in iaculis. Duis at consectetur lorem donec massa sapien faucibus et molestie. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eu augue ut lectus arcu bibendum at. Ac ut consequat semper viverra nam libero justo.',
                  },
                  {
                    text: 'Morbi tinciduus viverra accumsan. Sed arcu non odio euismod lacinia. Risus quis varius quam quisque id diam. At varius vel pharetra vel turpis nunc eget lorem. Habitasse platea dictumst vestibulum rhoncus. Tempor orci dapibus ultrices in iaculis. Duis at consectetur lorem donec massa sapien faucibus et molestie. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eu augue ut lectus arcu bibendum at. Ac ut consequat semper viverra nam libero justo.',
                  },
                  {
                    text: 'Morbi tinciduus viverra accumsan. Sed arcu non odio euismod lacinia. Risus quis varius quam quisque id diam. At varius vel pharetra vel turpis nunc eget lorem. Habitasse platea dictumst vestibulum rhoncus. Tempor orci dapibus ultrices in iaculis. Duis at consectetur lorem donec massa sapien faucibus et molestie. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eu augue ut lectus arcu bibendum at. Ac ut consequat semper viverra nam libero justo.',
                  },
                  {
                    text: 'Morbi tinciduus viverra accumsan. Sed arcu non odio euismod lacinia. Risus quis varius quam quisque id diam. At varius vel pharetra vel turpis nunc eget lorem. Habitasse platea dictumst vestibulum rhoncus. Tempor orci dapibus ultrices in iaculis. Duis at consectetur lorem donec massa sapien faucibus et molestie. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eu augue ut lectus arcu bibendum at. Ac ut consequat semper viverra nam libero justo.',
                  },
                ],
              },
              backgroundType: BackgroundType.None,
              backgroundVideo: null,
              backgroundImage: null,
              backgroundColor: {
                type: ColorType.White,
              },
            },
          ],
          id: '88.360006168011',
        },
        {
          __typename: 'EditorialBanner',
          layout: EditorialBannerLayoutEnum.TwoMediaRightTopText,
          modules: [
            {
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=7c7009bf-9a52-4f14-8348-36dfff9a5638&name=5-1_46440_355x469&inputFormat=jpg',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
            {
              link: null,
              content: {
                title:
                  'LOREM IPSUM DOLOR SIT AMET, SEA TE MUNERE PERTINAX TORQUATOS LOREM IPSUM DOLOR SIT AMET TORQUATOS COPED.',
                textCollection: null,
              },
              backgroundType: BackgroundType.None,
              backgroundVideo: null,
              backgroundImage: null,
              backgroundColor: {
                type: ColorType.White,
              },
            },
            {
              content: {
                title: '',
                textCollection: null,
              },
              backgroundType: BackgroundType.Image,
              backgroundVideo: null,
              backgroundImage: {
                url: 'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=0e2b345d-8c3f-49b4-9301-a8b648c9a2e5&name=5-2_46440_487x292&inputFormat=jpg',
              },
              backgroundColor: {
                type: ColorType.Brown,
              },
            },
          ],
          id: '97.54015928669772',
        },
      ],
    },
  },
};
