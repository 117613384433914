import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(4),
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
}));
