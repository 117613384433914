import { Sort } from '@ori-ui/icons';
import { ClickAwayListener, Typography } from '@ori-ui/mui';
import { PlpOrderBy } from '@ori/editorial-fetching';
import { useDisclose, useListenToKeyDown } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import type { FC, MouseEvent } from 'react';
import { useCallback, useRef, useState } from 'react';

import { SORTING, SORTING_MENU } from './constants';
import { SortButton, SortItem, SortMenu } from './styles';
import type { SortingProps } from './types';

/**
 * Sorting component
 *
 * State: Candidate for component extraction when it will be reused in design.
 *
 * @param options
 * @param onChange
 * @constructor
 */
export const Sorting: FC<SortingProps> = ({ options, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState(options[0]?.label ?? PlpOrderBy.Recommended);
  const { getTestId } = useTestId();
  const { onClose, onToggle, open } = useDisclose();

  const onButtonClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onToggle();
    },
    [onToggle],
  );

  useListenToKeyDown(['Escape'], () => {
    onClose();
  });

  const onClick = useCallback(
    (value: PlpOrderBy, displayText: string) => () => {
      setSelected(displayText);
      onClose();
      onChange(value);
    },
    [onChange, onClose],
  );

  if (!options.length) {
    return null;
  }

  return (
    <>
      <SortButton
        ref={ref}
        data-testid={getTestId(SORTING)}
        onClick={onButtonClick}
      >
        <Sort />
        <Typography
          fontWeight="bold"
          color="textPrimary"
          textTransform="uppercase"
        >
          {selected}
        </Typography>
      </SortButton>
      <ClickAwayListener onClickAway={onClose}>
        <SortMenu
          data-testid={getTestId(SORTING_MENU)}
          open={open}
          anchorEl={ref.current}
          placement="bottom-end"
        >
          {options.map((option) => (
            <SortItem
              key={option.key}
              value={option.key}
              onClick={onClick(option.key, option.label)}
            >
              {option.label}
            </SortItem>
          ))}
        </SortMenu>
      </ClickAwayListener>
    </>
  );
};
