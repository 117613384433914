import { styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

export const ContentRowsRoot = styled('div', { shouldForwardProp: shouldForwardProp() })<{
  gridRowStyle: string;
}>(({ theme, gridRowStyle }) => ({
  display: 'grid',
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(6),
  margin: 0,
  width: '100%',
  height: '100%',
  gridTemplateColumns: '1fr 1fr',

  [theme.breakpoints.up('sm')]: {
    columnGap: theme.spacing(3),
    gridTemplateColumns: gridRowStyle,
  },
}));
