import type { ProductListingRowsFragment } from '@ori/editorial-fetching';
import { isNonNullable } from '@ori/ts-utils';

import { type FacetFilterValues } from '../components/FacetFilter';
import { getFacetItemFromApi } from './getFacetItemFromApi';

/**
 * Transforms the facets data from the API into a format suitable for the FacetFilter component.
 *
 * @param facets - The facets data from the API. It's a part of the ProductListingRowsFragment.
 * @returns An array of FacetFilterValues, each representing a facet filter category with its label, key, and items (facets).
 * Each item represents a filter option with its label, selection state, value, and type.
 *
 * Note: Currently, only the default filter option is supported because there is no color filter option in the API.
 * Also, the returned array is sorted by the label of the facet filters in ascending order.
 */
export const mapFacetFilterFromApi = (facets: ProductListingRowsFragment['facets']): FacetFilterValues[] =>
  facets
    ?.filter(isNonNullable)
    .filter((facet) => facet.displayName && facet.name)
    .map((facet) => ({
      label: facet.displayName ?? '',
      key: facet.name ?? '',
      items: facet.values?.map(getFacetItemFromApi) ?? [],
    })) ?? [];
