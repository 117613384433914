import { Divider, styled } from '@ori-ui/mui';

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: 981,
  flexDirection: 'column',
  gap: theme.spacing(4),
  margin: theme.spacing(0, 'auto'),
  flexWrap: 'wrap',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8),
    width: '100%',
  },
}));

export const Root = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'block',
  margin: theme.spacing(0, 'auto'),
  padding: theme.spacing(0, 3),
  width: '100%',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '95%',
  height: 2,
  backgroundColor: theme.palette.grey[100],
  margin: theme.spacing(0, 'auto'),

  [theme.breakpoints.up('sm')]: {
    margin: 0,
    width: '100%',
  },
}));
