import type { TypographyProps } from '@ori-ui/mui';

export enum FilterOptionType {
  Default = 'default',
  Color = 'color',
}

interface BaseFilterOptionProps {
  onClick?: () => void;
  onDelete?: () => void;
  LabelProps?: TypographyProps;
  OptionProps?: {
    height?: number;
    iconSize?: number;
    selectedIconSize?: number;
  };
}

export interface FilterItemDefault {
  value: string;
  label: string;
  selected: boolean;
  type?: FilterOptionType;
}

export interface FilterItemColor extends FilterItemDefault {
  hexColors: string[];
}

export type FilterItem = FilterItemColor | FilterItemDefault;

export type ColorFilterOptionProps = BaseFilterOptionProps & FilterItemColor;
export type DefaultFilterOptionProps = BaseFilterOptionProps & FilterItemDefault;

export type FilterOptionProps = ColorFilterOptionProps | DefaultFilterOptionProps;
