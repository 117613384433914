/* istanbul ignore file */
import { useToken } from '@ori/auth';
import { useEnvironment } from '@ori/environment';
import { TranslationsNamespaceProvider, useLanguage } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import { HeadersProvider, UserSegmentationDataProvider } from '@ori/presentation-http';
import { Team, TestIdProvider } from '@ori/testid-generator';
import type { FC } from 'react';

import { APPLICATION_NAME, APP_NAME, translationsNamespace } from '../../constants';
import { ProductListingProvider } from '../../contexts';
import { mainLogger } from '../../logger';
import { ApiProvider } from '../ApiProvider';
import { SearchResultsPage } from '../SearchResultsPage';
import type { SearchResultsPageContainerProps } from './types';

export const SearchResultsPageContainer: FC<SearchResultsPageContainerProps> = ({ hideRating }) => {
  const { language } = useLanguage();
  const { tenant } = useEnvironment();
  const { token } = useToken();

  return (
    <TestIdProvider
      team={Team.Presentation}
      project={APP_NAME}
    >
      <LoggerProvider logger={mainLogger}>
        <ErrorBoundary areaName="SearchResultsPageContainer">
          <TranslationsNamespaceProvider namespace={translationsNamespace}>
            <ApiProvider>
              <UserSegmentationDataProvider>
                <HeadersProvider
                  language={language}
                  sourceAppName={APPLICATION_NAME}
                  tenant={tenant}
                  token={token}
                >
                  <ProductListingProvider hideRating={hideRating}>
                    <SearchResultsPage />
                  </ProductListingProvider>
                </HeadersProvider>
              </UserSegmentationDataProvider>
            </ApiProvider>
          </TranslationsNamespaceProvider>
        </ErrorBoundary>
      </LoggerProvider>
    </TestIdProvider>
  );
};
