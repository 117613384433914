import type { PopperProps } from '@ori-ui/mui';

export const POPPER_MODIFIERS: PopperProps['modifiers'] = [
  {
    name: 'flip',
    enabled: false,
  },
  {
    name: 'offset',
    options: {
      offset: [0, 5],
    },
  },
];

export const FILTER_DROPDOWN = 'filter-dropdown';
export const FILTER_DROPDOWN_CLOSE_BUTTON = 'filter-close-button';
export const FILTER_DROPDOWN_SUBMIT_BUTTON = 'filter-submit-button';
export const FILTER_DROPDOWN_CLEAR_BUTTON = 'filter-on-clear-button';
