import useTestId from '@ori/testid-generator';
import { type FC } from 'react';

import { CONTENT_ROWS } from '../../constants';
import { getGridStyleFromData } from '../../utils';
import { ContentRow } from '../ContentRow/ContentRow';
import { ContentRowRoot } from './styles';
import type { ContentRowsProps } from './types';

/**
 * Used to render content rows
 * It generates grid based on the data
 *
 * @constructor
 * @param props
 */
export const ContentRows: FC<ContentRowsProps> = ({ rows }) => {
  const { getTestId } = useTestId();

  return rows?.map((data, index) => (
    <ContentRowRoot
      /* eslint-disable-next-line react/no-array-index-key -- I don't have any other key */
      key={index}
      gridRowStyle={getGridStyleFromData(data)}
      data-testid={getTestId(CONTENT_ROWS)}
    >
      <ContentRow data={data} />
    </ContentRowRoot>
  ));
};
