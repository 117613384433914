import { PlpOrderBy, type ProductListingRowsFragment } from '@ori/editorial-fetching';
import { isNonNullable } from '@ori/ts-utils';

import type { SortingMenuItem } from '../components';

/**
 * Transforms the orderBy options data from the API into a format suitable for the Sorting component.
 *
 * @param options - The orderBy options data from the API. It's a part of the ProductListingRowsFragment.
 * @returns An array of SortingMenuItem, each representing a sorting option with its key and label.
 */
export const mapOrderByFromApi = (options: ProductListingRowsFragment['orderByOptions']): SortingMenuItem[] =>
  options?.filter(isNonNullable).map((option) => ({
    key: option.orderBy ?? PlpOrderBy.Recommended,
    label: option.displayText ?? PlpOrderBy.Recommended,
  })) ?? [];
